.Location {
    // .howToGetCoordinates,
    // .fieldDetails {
    //     font-size: .9rem;
    // }

    .pseudoLink {
        font-size: .9rem;
    }

    .showHowToGetCoordinates {
        font-size: .9rem;
        color: #555;
    }

    .FormFieldsGroup {
        .howToGetCoordinatesWrap {
            padding-top: 0;
            margin-top: 0;
        }

        // .howToGetCoordinates {
        //     margin-bottom: 0;
        // }
    }
}
