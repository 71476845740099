.DialogContentWrapper {
    .SignIn {
        width: 500px;
    }
}

.SwipeableDrawerContentWrapper {
    .SignIn {
        margin-bottom: 2rem;
    }

    .registration {
        margin-top: -2rem;
    }
}
