.ServicesTemplate {
    .SaleItemPrice {
        // background: rgba(255, 255, 255, .9);
        // padding: 5px 10px;
        border-radius: 15px;
        font-size: 1rem;
        white-space: nowrap;
    }

    // .pseudoButton.errorButton {
    //     background: rgb(211, 47, 47) !important;
    //     color: #fff !important;
    //     padding: .5rem 1rem!important;
    // }
    .ShopingCartButton {
        margin-left: 1rem;
    }

    .ServiceTitle {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .LinksTileImageLink {
            font-size: 1.2rem;
        }
    }

    .ServiceCategories {
        text-align: right;
        .MuiChip-root {
            margin-left: 1rem;
        }
    }
}
