.ShopProductsList {
    margin-bottom: 3rem;
    padding: 0 .5rem;

    // .MuiGrid-root {
    //     margin-bottom: 1rem;
    // }

    .productWrap {
        position: relative;

        &.productNotAvailable {
            opacity: .9;
        }
    }

    .MuiGrid-item.product {
        // margin-bottom: 2rem;
        // padding-top: 1rem;
    }

    .ShopCategoryLabel {
        // padding-bottom: 2rem;
        // padding-left: 1rem;
    }

    .productTitle {
        display: flex;
        align-items: center;
        height: 65px;
        padding-left: .5rem;
        line-height: 1.3;
        padding-top: .5rem;
    }

    .ShopProductPrice {
        margin-left: .5rem;
    }

    .photoWrap {
        // height: 156px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
    }

    .button {
        margin-bottom: 1rem;
    }

    .ShopProductPrice {
        font-size: 1.3rem;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        white-space: nowrap;

        .oldPrice {
            font-size: 1rem;
        }

        @media (max-width: 599px) {
            font-size: 18px;
        }
    }

    .ShopingCartButton {
        text-align: left;

        .MuiSvgIcon-root {
            width: 16px;
        }

        .ShopProductPrice {
            font-size: 0.8rem;
            height: auto;

            .oldPrice {
                color: #bbb;
                font-size: .7rem;
            }
        }
    }
}
