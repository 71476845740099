.MuiButtonBase-root.buttonWithPreloader {
    position: relative;

    &.loading {
        transition: color .5s ease-in-out;
        color: #777;
    }

    .MuiCircularProgress-root {
        position: absolute;
        color: #fff;
        z-index: 1;
    }

    &.MuiButton-outlined {
        &.loading {
            color: #ccc;
        }

        .MuiCircularProgress-root {
            color: #333;
        }        
    }
}
