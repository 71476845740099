.uri_spilnota_biznes-z-rosiyanamy {
  .td_thumbnail {
    img.tableImages {
      border-radius: 50%!important;
    }
  }

  .Details {
    .label {
      display: none;
    }
  }
}
