.FullHeightContentBlockWrap {
    margin-left: -16px;
    margin-right: -16px;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 1px;
        box-shadow: 0 4px 10px #fff;
        z-index: 999;
    }
}

.FullHeightContentBlock {
    overflow: auto;
    position: relative;
    padding-left: 16px;
    padding-right: 16px;

    .TableAutoWrap {
        overflow: visible;
    }

    &.hidden {
        overflow: hidden;
    }

    .FullHeightContentBlockContent {
        &.hidden {
            opacity: 0;
        }
    }
}
