.Tour {
    .date {
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #ccc;
    }

    .dates {
        display: flex;
        align-items: center;
        font-size: .9rem;

        padding-left: .8rem;

        @media (min-width: 600px) {
            padding-left: 1.5rem;
        }

        svg {
            margin-right: .3rem;
        }
    }

    .details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #ccc;
        margin-top: .5rem;
        padding-top: .5rem;
    }

    .price {
        font-size: 18px;
        margin: 0;

        .ShopProductPrice {
            margin: 0;
            padding: 0;;
        }
    }

    figure,
    img {
        width: 100%;
        margin: 0;
        border-radius: 15px;
    }

    .Actions {
        margin-bottom: 1rem;
    }

    .TourDetails {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    @media (max-width: 599px) {
        .ShopingCartButton {
            width: 100%;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }
}
