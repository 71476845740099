.NodeFilters {
    .MuiDialogContent-root {
        width: 440px;
    }
}

.FilterButtonWidget {
    .FilterWidgetClose {
        cursor: pointer;
        position: absolute;
        right: 1rem;
        top: 1.5rem;
    }

    .sortDirection {
        display: flex;

        .MuiButtonBase-root {
            margin-right: .5rem;
            // padding-left: 0;
            // padding-right: 0;
            min-width: auto;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.FilterWidgetRangeLabel {
    font-size: 1rem;
    padding-left: .5rem;
    padding-bottom: .2rem;
    margin-top: -.5rem;
}
