.Product {
    .photo {
        width: 100%;
        border-radius: 15px;
    }
    
    .notAvailable {
        padding-left: 1.5rem;
        padding-bottom: 2rem;
        color: #ae0000;
    }

    .ShopingCartButton {
        padding-bottom: 2rem;
    }

    .ShopProductPrice {
        font-size: 2rem;
        margin-top: -1rem;
        display: flex;
        align-items: center;

        .oldPrice {
            font-size: 1rem;
            margin-right: .5rem;
            padding-left: 1.5rem;
        }
    }
}
