.HistoryNodesAdminPage {
    .FiltersLine {
        margin-bottom: 1rem;
    }

    .labelWrap.node {
        align-items: start;

        .labelValue {
            padding-top: .2rem;
        }
    }

    .RestoreAction {
        margin-bottom: 2rem;
    }

    .labelWrap.patch {
        display: block;
    }

    .labelWrap.path {
        @media (max-width: 599px) {
            display: block;
        }
    }
}

.HistoryNodeAdminPageDetails {
    padding-bottom: 1rem;
}
