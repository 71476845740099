.LibraryTemplate {
    .bookTitle {
        font-size: 1.1rem;
        line-height: 1.2rem;
    }

    .bookAuthors {
        color: #777;
        font-size: .8rem;
    }

    .CategoryWrap {
        margin-bottom: 2rem;

        .items {
            overflow-x: auto;
            overflow-y: hidden;

            .MuiGrid-container {
                flex-direction: column;
                align-content: flex-start;
                height: 295px
            }

            .MuiGrid-item {
                width: 150px;
            }

            .photoWrap {
                margin-bottom: .5rem;
            }

            .bookTitle {
                font-size: 1rem;
                line-height: 1rem;
                height: 32px;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }

            .bookAuthors {
                font-size: .8rem;
                text-wrap: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .subtitle {
                padding-right: 0;
                padding-left: 0;
                overflow: hidden;
                width: 100%;
            }
        }
    }
}
