.GalleryUploadField {
    .formPhotos {
        margin-top: 1rem;
        // margin-bottom: 1rem;
    }

    .formPhotos {
        margin-top: 0;
    }
}
