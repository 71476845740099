.Banner {
    position: relative;
    font-size: 0;

    display: flex;
    flex-direction: column;

    .BannerImgWrap {
        border-radius: 10px;
        overflow: hidden;

        position: relative;

        .external {
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 3;
            font-size: 12px;
            border-left: 3px solid #fff;
            border-top: 3px solid #fff;
            // background: rgb(0 0 0 / 50%);
            background: #fff;
            border-top-left-radius: 15px;
            padding: 0 .5rem;
            color: #000;
            text-decoration: none;

            display: flex;
            justify-content: center;

            svg {
                width: 1rem;
                color: #333;
            }
        }

        .BannerImg {
            width: 100%;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);
        }

        .image {
            position: absolute;
            left: 0;
            top: 0;
            width: calc(100% - 15px);
            height: calc(100% - 15px);
            background-size: auto 100%;
            background-repeat: no-repeat;
            background-color: transparent;
            z-index: 2;

            background-position: 0 50%;

            border: 8px solid #fff;
            border-radius: 15px;

            &.right {
                background-position: 100% 50%;
            }

            @media (min-width: 600px) {
                background-position: 50% 50%;
                border: 0;
                width: 100%;
                height: 100%;

                &.right {
                    background-position: 50% 50%;
                }
            }
        }

        .backgroundLeft,
        .backgroundRight {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 120px;
            z-index: 1;
        }

        .backgroundLeft {
            right: auto;
            left: 0;
        }
    }

    .BannerLabel {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 10px;
        background: #fff;
        color: #000;
        font-size: 12px;
        opacity: 0.9;
        border-radius: 10px;
    }

    .BannerText,
    .BannerButton {
        display: block;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 16px;
        text-align: center;
        padding: .5rem;

        position: absolute;
        bottom: 1rem;
        width: calc(100% - 1rem);

        background: rgba(255, 255, 255, 0.95);
        color: #000;
        border-radius: 10px;
        box-sizing: border-box;

        margin: 0 .5rem;
    }

    @media (min-width: 600px) {
        .BannerImgWrap {
            position: relative;
            overflow: hidden;
            border-radius: 10px;
            // box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);

            .BannerImg {
                height: 90%!important;
                width: auto!important;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                box-shadow: none;
            }
        }
    }
}

.BannerWithButton {
    .BannerButton {
        position: relative;
        bottom: auto;
        width: 100%;
        margin: .5rem 0;
    }
}
