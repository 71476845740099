.CKEditorContent {
    line-height: 1.5rem;
    font-size: 1rem;

    & > p:first-child {
        margin-top: 0;
    }

    .table {
        table {
            width: auto;
            border-collapse: collapse;

            th, td {
                text-align: left;
                border-bottom: 1px solid #ccc;
                border-top: 1px solid #ccc;
                padding: .5rem 1rem;
            }

            th {
                background: #f5f5f5;
            }
        }
    }

    img {
        border-radius: 15px;
    }
}

.ck-content .table {
    margin: .9em 0;

    table {
        border: 0;

        td, th {
            border: 0;
            border-bottom: 1px solid #ccc;
            border-top: 1px solid #ccc;   
        }
    }

    blockquote {
        border: 0;
        background: #efefef;
        padding: 1rem;
        border-radius: 15px;
        display: block;
        margin-bottom: .5rem;
        margin-left: 1rem;
    }
}

.CKEditorContent {
    line-height: 1.5rem;
    font-size: 1rem;
    margin-bottom: 2rem;

    padding: 0 1rem;

    p:first-child {
        margin-top: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }

    blockquote {
        background: #efefef;
        padding: 1rem;
        border-radius: 15px;
        display: block;
        margin-bottom: .5rem;
        margin-left: 1rem;
    }

    @media (min-width: 600px) {
        padding-left: 1.5rem;
        padding-right: 1rem;
    }
}

.TableWrap {
    .CKEditorContent {
        margin-top: .5rem;

        p:last-child {
            margin-bottom: 0;
        }
    }
}
