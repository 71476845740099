.ReaderInfo {
    margin-top: -.5rem;
    min-height: 170px;

    .Avatar {
        width: 100%;
        height: auto;
    }

    .UserInfo {
        position: relative;

        float: left;
        width: 80px;
        margin-right: 1rem;
        margin-top: 0;
    }

    .confirmed {
        &:after {
            content: "CONFIRMED";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 20px;
            color: #005b0f;
            text-align: center;
            width: auto;
            font-weight: 900;
            background: rgba(255, 255, 255, .95);
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, .2);
            padding-left: .5rem;
            padding-right: .5rem;
            font-size: 12px;
        }
    }

    .actions,
    .links {
        margin: 0;
        clear: none;
    }

    .buttons {
        .MuiButton-root {
            margin-bottom: 1rem;
        }
    }

    .pname {
        font-size: 1.2rem;
        // margin-bottom: 2rem;
    }

    .actions {
        margin-left: 106px;
        margin-bottom: 3rem;
    }
}

.DialogContentWrapper {
    .ReaderInfo {
        width: 500px;
    }
}
