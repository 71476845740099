.UpdatePassword {
    .preloaderWrap {
        height: 200px;
    }

    .resetDescription {
        margin-bottom: 2rem;
    }

    .informer {
        border-radius: 10px;
        padding: 5px 1rem;
        margin-bottom: 1rem;

        &.error {
            color: #fff;
            background: rgba(230, 26, 26, 0.98);
        }
    }
}
