.ProfilePage {
    .userDetails {
        .globalInformer {
            margin-top: 1rem;
            margin-bottom: 1rem;
            margin-right: 3rem;

            svg {
                display: inline-block;
                margin-right: 5px;
                position: relative;
                top: 6px;
                margin-top: -6px;
            }
        }
        
        .action {
            padding-top: 1rem;
        }

        // .verifyEmailWrap {
        //     border-radius: 15px;
        //     background: rgba(230, 26, 26, 0.98);
        //     padding: 1rem;
        // }

        .userDetails {
            padding-top: 2rem;
            padding-left: 2rem;
        }

        .verifyEmail {
            color: #fff;

            svg {
                position: relative;

                margin-right: 5px;
                top: 6px;
                margin-top: -6px;
            }

            .verifyEmailLink {
                display: block;
                cursor: pointer;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .UserActions {
        padding-left: 1rem;
        padding-bottom: 1rem;
        margin-top: -1rem;

        .MuiSvgIcon-root {
            font-size: 0;
            border-radius: 50%;
            width: 1.2rem;
            height: 1.2rem;
            padding: .8rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: .5rem;
            cursor: pointer;
            border: 1px solid #ccc;
        }

        .MuiSvgIcon-colorError {
            border-color: #d32f2f;
        }
    }
}

.profileForm {
    margin: 0 auto;

    & > p:first-child {
        margin-top: 0;
    }

    .pageTitle {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }

    &.profile {
        margin-top: .5rem;
    }

    @media (min-width: 600px) {
        &.profile {
            margin-top: 2rem;
        }
    }

    .action {
        border: 0!important;
        text-align: left!important;
        display: inline-block;
        padding-left: 1rem;
        // width: auto;

        // @media (min-width: 600px) {
        //     text-align: center!important;
        // }

        &:hover {
            text-decoration: underline;
        }

        .MuiButton-startIcon {
            position: relative;
            top: 5px;
        }
    }

    .goBack {
        width: 2rem;
        height: 2rem;
        margin-left: -1rem;

        svg {
            width: 2rem;
            height: 2rem;
        }

        &:hover {
            opacity: .5;
        }
    }

    .rules {
        text-align: center;

        a {
            margin: 0 1rem;
        }
    }
}

.DialogContentWrapper,
.SwipeableDrawerContentWrapper {
    .profileForm {
        .pageTitle {
            display: none;
        }
    }
}
