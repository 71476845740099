.ConversationPage {
    .pageTopTitle {
        // padding-bottom: 0;

        .pageTitle {
            display: flex;
            align-items: center;

            // .AvatarWrap {
            //     height: 28px;
            //     margin-right: .5rem;
            // }

            .ConversationTopicrDialog,
            .AvatarWrap,
            .TopicAvatar {
                width: 30px;
                height: 30px;
                min-width: 30px;
                margin-right: 1rem;

                .MuiIcon-root {
                    font-size: 1rem;
                }
            }
        }
    }

    .ConversationBadge,
    .NotificationBadge {
        position: relative;
        top: 1px;
    }

    .FiltersLine {
        margin-bottom: 1rem;
    }

    // .TableWrap table.Table td:first-child {
    //     padding-left: .5rem;
    // }

    .TableWrap table.Table .item {
        display: block;
        margin-bottom: 1rem;
        max-width: 100%;
    }

    .td_message {
        display: block;
        box-sizing: border-box;
        width: 100%;
        // padding-left: 1rem;
        // padding-right: 1rem;
    }

    .CellMessage {
        .timestamp {
            // position: absolute;
            font-size: 14px;
            // top: 100%;
            // margin-top: 1rem;
        }
    }

    .TableWrap table.Table .item {
        // margin-bottom: 2rem;

        &.tr_sender .timestamp {
            right: 0;
        }

        &.tr_recepient .timestamp {
            left: -86px;

            // @media (min-width: 600px) {
            //     margin-top: 1.3rem;
            // }
        }
    }

    .pageTopTitle {
        .pageTitle {
            width: 100%;
            position: relative;
            // padding-left: 32px;

            // .AvatarWrap {
            //     position: absolute;
            //     left: -4px;
            //     top: 3px;
            // }

            .MuiBadge-root {
                white-space: nowrap;
                // margin-left: 1.2rem;

                .MuiBadge-badge {
                    right: 20px;
                }
            }

            // // @media (max-width: 599px) {
            // // .MuiBadge-root {
            //     margin-left: 0;
            //     position: absolute;
            //     right: 1rem;
            //     top: 1.2rem;
            // }
            // // }

            .Avatar {
                width: 28px;
                height: 28px;
                min-width: 28px;
                min-height: 28px;
            }
        }
    }

    .TableWrap table.Table .tr_sender {
        margin-left: 3rem;
        width: calc(100vw - 6rem);

        @media (min-width: 600px) {
            width: auto;
            margin-left: 3rem;
            margin-right: 1rem;
        }
    }

    .TableWrap table.Table .tr_recepient {
        margin-right: 3rem;
        width: calc(100vw - 6rem);

        @media (min-width: 600px) {
            width: auto;
            margin-right: 7rem;
        }

        td {
            background-color: #eaf9ff;
        }
    }

    @media (min-width: 600px) {
        // .TableWrap table.Table .item {
            // max-width: 50%;
        // }

        .TableWrap table.Table td:first-child {
            padding-left: 1rem;
        }
    
        // .TableWrap table.Table .tr_sender {
        //     margin-left: 2rem;
        // }

        // .TableWrap table.Table .tr_recepient {
        //     margin-left: 1rem;
        // }
    }
}

.ConversationPage.ConversationDialogPage {
    position: relative;

    .FullHeightContentBlockContent {
        // height: calc(100% - 150px);
        height: 100%;
        overflow: auto;
        padding-right: 1rem;
    }

    .ConversationMessenger {
        position: absolute;
        bottom: -130px;
        height: 130px;
        left: 0;
        right: 0;
        display: flex;
        border-top: 10px solid #fff;
        box-shadow: 0 -10px 10px #fff;

        .buttons {
            width: 80px;
            margin-right: 2rem;
        }

        .textName {
            // margin-left: 3rem;
            width: 100%;
            margin-right: 1rem;

            .MuiButton-root {
                margin-bottom: 1rem;
            }
        }
    }
}

.FullHeightContentBlockWrap.hasPadding {
    .FullHeightContentBlock {
        margin-bottom: 130px;
    }
}
