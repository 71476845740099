.NodeContent {
    .ck-sticky-panel__content {
        display: none;

        @media (min-width: 600px) {
            display: block;
        }
    }
}

.DialogContentWrapper {
    .NodeContent {
        padding-top: 0.5rem;
    }
}


.SwipeableDrawerContentWrapper {
    .NodeContent {
        padding-bottom: 3rem;
    }
}

.DialogContentWrapper,
.SwipeableDrawerContentWrapper {
    .NodeContent {
        // .FormSubmitButtonWrap {
        //     height: 72px;
        // }

        .FormSubmitButton {
            background: #fff;
            position: fixed;
            left: 0;
            right: 0;
            z-index: calc(var(--ck-z-modal) + 1);

            width: 100%;
            padding: 1rem;
            box-sizing: border-box;
            box-shadow: 0 -10px 20px #fff;

            top: 100%;

            padding-bottom: 2rem;
            margin-top: -106px;
        }
    }

    .CheckboxField {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .progressBlock {
        width: calc(100% - 16px);
        height: 260px;
    }

    .titleEmoji {
        text-align: center;
        font-size: 5rem;
        margin: 0;
        margin-bottom: 2rem;
    }

    .titleEmojiTitle {
        margin-top: 0;
        font-size: 1.2rem;
    }

    .titleEmojiText {
        text-align: center;
        color: #555;
        margin-bottom: 2rem;
        padding: 0 1rem;
    }

    .depositInfo {
        margin-bottom: 1rem;
    }

    .contact {
        text-align: center;
    }

    .centeredText {
        text-align: center;
    }
}
