.InlineActions {
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .actions {
        position: absolute;
        right: 50px;
        top: 0;

        display: flex;

        .ActionButton {
            margin-right: 0;
            margin-left: .3rem;
            margin-right: .3rem;
        }

        .MuiSvgIcon-root {
            box-shadow: 0 0 50px #fff;
        }
    }
}
.LinksLines,
.NodeLinesTemplate {
    .InlineActionsUp .actions {
        width: 100%;

        .ActionButton {
            width: 21%;
            padding-bottom: 21%;

            @media (min-width: 600px) {
                width: 18%;
                padding-bottom: 18%;
            }
        }
    }
}

.ActionsCell {
    .InlineActions {
        top: 1px;
    }
}

.InlineActionsUnfolded {
    white-space: nowrap;
    width: 100%;
    padding: 0;

    .ActionButton {
        margin: 0 .5rem;
    }
}

.InlineActionsUp {
    .actions {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: end;

        right: 0;
        top: 50px;

        width: 100%;

        @media (min-width: 600px) {
            right: 0;
        }
    }

    &.InlineActionsOpen {
        width: 100%;
        // margin-top: -52px;
        justify-content: end;
    }
}

.CurrentPageInlineActions {
    position: absolute;
    right: 7px;
    top: 13px;
    z-index: 1;
    width: auto;

    .actions {
        background: #fff;
        box-shadow: 0 0 20px #fff;
    }
}

.ShopProductsList,
.LinksTile {
    .InlineActions {
        position: absolute;

        right: 0;
        top: 3px;
    }

    .actions {
        .ActionButton {
            position: relative;
            width: 50%;
            padding-bottom: 50%;
            height: 0;
            margin-left: 0;
            margin-right: 0;

            .MuiSvgIcon-root {
                box-sizing: border-box;
                position: absolute;
                left: 10%;
                top: 10%;
                width: 80%;
                height: 80%;

                padding: 1rem;

                @media (min-width: 600px) {
                    left: 15%;
                    top: 15%;
                    width: 70%;
                    height: 70%;
                }
            }
        }
    }
}

.NodeLinesCompactTemplate {
    @media (max-width: 599px) {
        .InlineActionsOpen {
            margin-top: 0;
        }
    }
}

.LibraryTemplate {
    .InlineActionsUp {
        margin-top: 0;
    }

    .LinksTile {
        .actions {
            .ActionButton {
                .MuiSvgIcon-root {

                    padding: .8rem;

                    @media (min-width: 600px) {
                        left: 15%;
                        top: 15%;
                        width: 70%;
                        height: 70%;
                        padding: .7rem;
                    }
                }
            }
        }
    }
}

.childrensList {
    .actions {
        background: #fff;

        .MuiSvgIcon-root {
            box-shadow: 0 0 50px #fff;
        }
        
    }
}

.TableWrap {
    .InlineActions {
        @media (max-width: 599px) {
            margin-top: 1rem;
        }
    }
}
