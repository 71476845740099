.Recomendation {
    overflow: hidden;

    .RecommendationCard {
        margin-bottom: 2rem;

        //.pseudoButton,
        .MuiButtonBase-root{
            margin-bottom: 1rem;
        }

        @media (min-width: 600px) {
            margin-bottom: 0;
            width: 240px;
            float: left;
            margin-right: 2rem;
        }

        @media (max-width: 599px) {
            .thumbnail {
                margin-bottom: 2rem;
            }
        }
    }

    .tags {
        margin-top: 2rem;
    }

    .LocationWidget {
        clear: both;

        @media (min-width: 600px) {
            padding-top: 1rem;
        }

        @media (max-width: 599px) {
            .labelWrap {
                display: block;
            }
        }
    }

    .RecomendationDescription {
        margin-bottom: 2rem;
    
        .labelWrap {
            padding-left: 0;
        }

        p {
            margin-top: 0;
        }
    }
}
