body.virtual-keyboard-attached {
    .MuiPaper-root {
        overflow: visible;
        max-height: none;
        top: 20px;
        transition: top .5s ease-in-out,
                    max-height .5s ease-in-out;
    }

    .SwipeableDrawerContentWrapper {
        overflow: auto;
        height: calc(100vh - 160px);
        max-height: none;
    }

    .SwipeableDrawerContentWrapperInner {
        padding-bottom: 40vh;
    }
}
