// .UserSingeltonesList {
//     .cityValues {
//         margin-bottom: 1rem;
//     }
// }

.UserSingeltonesList {
    margin-bottom: 1rem;

    i {
        opacity: .5;
    }
}
