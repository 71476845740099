.TimestampFieldWrap {
    .TimestampField {
        width: 100%;
    }

    .locale {
        font-size: .8rem;
        padding-left: .5rem;
        color: #888;
    }
}
