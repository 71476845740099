.CellMessage,
.CellConversation {
    // .content {
    //     margin-bottom: .5rem;
    // }

    .timestamp {
        opacity: .5;
        font-weight: normal;
        margin: 0;
        // margin-top: -.5rem;
    }

    .CellConversationTitle {
        font-weight: bold;
        margin-bottom: .2rem;
        font-size: 1.2rem;
        margin-top: -.3rem;
        // a {
        //     margin-right: 1rem;
        // }
    }

    .text {
        position: relative;
    }
}

.CellMessage {
    // overflow: hidden;
    display: flex;
    align-items: start;

    @media (min-width: 600px) {
        align-items: center;
    }

    .avatar {
        margin-right: 1rem;
    }

    .text {
        width: calc(100vw - 162px);

        @media (min-width: 600px) {
            width: 100%;
        }
    }

    &.sender,
    &.privateConversation {
        .avatar {
            display: none;
        }

        .text {
            width: 100%;
        }
    }
}

// .ConversationBadge {
//     .MuiBadge-badge {
//         right: 10px;
//         top: 10px;
//     }
// }

.tr_unseen {
    .CellConversation .NotificationTitle {
        font-weight: normal!important;
    }

    .CellMessage .NotificationTitle {
        font-weight: bold!important;
    }
}

.td_conversation-logo {
    padding-right: .2rem;
}
