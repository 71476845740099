.NotificationWidget {
    display: flex;
    flex-direction: column;

    @media (min-width: 600px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .MuiChip-root {
        margin-right: .5rem;
    }
}

.NotificationTitle {
    // line-height: 1.1rem;
}

.NotificationSubitle {
    font-size: .8rem;
    font-style: italic;
    opacity: .6;
}

.NotificationActions {
    // overflow: hidden;
    // margin-top: 1rem;
    margin-bottom: 0;

    .MuiChip-root {
        padding: 1rem;
        font-size: 14px;
    }

    @media (max-width: 599px) {
        margin-top: .5rem;

        .MuiChip-root,
        .MuiButtonBase-root {
            width: 100%;
        }

        .MuiChip-root {
            margin-bottom: .5rem;
        }

        .MuiButton-root {
            margin-bottom: .5rem;
        }
    }

    @media (min-width: 600px) {
        width: 220px;
        margin-right: -.5rem;
        margin-left: 1rem;

        .MuiButton-root {
            margin-bottom: .5rem;
        }
    }
}
