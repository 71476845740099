.UploadImage {
  position: relative;

  &.disabled {
    .MuiOutlinedInput-root.Mui-disabled {
      color: #aaa!important;
      border: 1px solid #eaeaea!important;
      background-color: #f9f9f9!important;
    }

    .action {
      opacity: 0.5;
    }
  }

  .formPhotos {
    width: 48px;
    height: 50px;

    position: absolute;
    top: 4px;
    right: 8px;
    z-index: 2;

    border-radius: 10px;

    .imgWrap {
      height: 100%;

      display: flex;
      flex-wrap: wrap;
    }

    .photo {
      height: 100%;

  
      align-items: center;
      justify-content: center;  
    }

    .photo img {
      max-height: 50px;
      background: #333;
    }
  }
}

.formPhotos {
  display: flex;
  align-items: center;
  justify-content: center;

  .photo {
    .name {
      word-break: break-all;
      font-size: 0.8rem;
      display: block;
      line-height: 1.2;
    }

    .close {
      cursor: pointer;
      display: block;
      position: absolute;
      right: -10px;
      top: -10px;

      font-size: 0;

      padding: 2px;

      background: #303030;
      color: #fff;
      border-radius: 50%;

      &:hover {
        opacity: 0.9;
      }
    }

    img {
      width: 100%;
      border-radius: 20px;
      margin-bottom: .5rem;
    }

    .imgWrap {
      position: relative;
      display: flex;
      align-items: center;
    }
  }
}

.fileUploadButton {
    .filesList {
      width: 100%;

      &.hasImage {
        border: 1px solid rgb(211, 47, 47);
      }
    }

    label {
      width: auto;
      white-space: nowrap;
    }

    label.Mui-disabled {
      &.Mui-error {
        color: rgb(211, 47, 47)!important
      }
    }

    label.action.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
    }

    .details {
      position: relative;
      color: #777;
      font-size: .8rem;
      display: flex;
      margin-bottom: 0;
      justify-content: space-between;
      align-items: flex-start;

      .originalControl {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: calc(100% - 80px);
        height: 100%;
        opacity: 0;
        cursor: pointer;
      }
    }

    .action {
      display: inline-flex!important;
      padding: 0!important;
      margin-left: 6px;
      min-height: 58px;
      height: 58px;
      width: 68px;

      &.error {
        background: rgb(211, 47, 47);
      }
    }
  }

  .containerForHiddenImages {
    display: none;
  }