.Shop {
    .LinksTile {
        margin-bottom: 3rem;
    }

    // .ShopProductsList {
    // }

    .ShopDescription {
        padding: 1rem;
        background: #f5f5f5;
        border-radius: 15px;
        overflow: hidden;

        @media (min-width: 600px) {
            padding: 1.5rem;
            padding-top: 1rem;
        }

        .CKEditorContent  {
            margin-bottom: 0;
        }

        .thumbnail {
            float: left;

            width: 100px;

            @media (min-width: 600px) {
                width: 150px;
                margin-left: 1rem;
                margin-right: 1.5rem;
            }

            margin-top: .5rem;
            margin-left: 0;
            margin-bottom: .5rem;
            margin-right: 1rem;
        }

        .ShopDescriptionTitle {
            margin-bottom: 1rem;
            font-size: 1.2rem;
        }
    }
}
