.Insurance {
    .pageTitle {
        padding-right: 2rem;
    }

    .MuiGrid-item {
        // padding-top: 20px!important;
    }

    .MuiFormControl-root {
        width: 100%;
    }

    .faqIcon {
        position: absolute;
        right: 1rem;
        top: 1.1rem;
        z-index: 1;

        cursor: pointer;
        color: #777;
        
        &:hover {
            color: #333;
        }
    }

    .divider {
        height: 1px;
        display: block;
        overflow: hidden;
        background: #ddd;
        margin: 1rem 0 1rem;
    }

    .step {
        font-style: italic;
        padding-bottom: 1rem;
        cursor: pointer;

        // font-size: .8rem;
        color: #777;

        &.active {
            font-size: 1.1rem;
            color: #111;

            .pseudoLink {
                display: none;
            }
        }

        .pseudoLink {
            margin-left: .5rem;
            font-size: 0.9rem;
            color: #333;
            font-style: normal;

            display: inline-flex;
            align-items: center;

            position: relative;
            top: 4px;

            white-space: nowrap;

            .MuiSvgIcon-root {
                width: 18px;
                height: 18px;
            }
        }
    }

    @media (min-width: 600px) {
        .InsuranceOptions {
            .swiper {
                padding-left: 5rem;
                padding-right: 5rem;
            }
        }
    }

    .InsuranceForm,
    .InsuranceOptions,
    .SubmitForm {
        .labelWrap {
            padding-left: 0;
        }
    }

    .InsuranceForm .Form,
    .InsuranceOptions .SummaryWrap {
        margin-bottom: 2rem;
    }

    .InsuranceForm .Form {
        .MuiGrid-container {
            margin-bottom: 1rem;
        }
    }

    .subtitle {
        position: relative;
        // font-weight: bold;
        font-size: 1.2rem;
        // margin-bottom: 2rem;
        padding-right: 2rem;

        display: flex;
        align-items: center;

        cursor: pointer;

        svg {
            margin-right: .5rem;
            margin-left: 0!important;
        }

        .arrow {
            display: none;

            margin: 0;
            border: 1px solid #ccc;
            border-radius: 50%;
            background: #fff;

            position: absolute;
            right: 0;
        }

        .down {
            display: inline-block;
        }

        &.on {
            .up {
                display: inline-block;
            }

            .down {
                display: none;
            }
        }

        // .MuiSvgIcon-root {
        //     margin-left: .5rem;
        // }
    }

    .subtitle2 {
        font-size: 1.1rem;
        padding-bottom: 1rem;
        font-style: italic;
    }

    .summaryDays {
        color: #777;
    }

    .faqTitle {
        display: flex;
        align-items: center;
    }

    .FAQ {
        padding-top: 1rem;
    }

    .SupportWrapperForm {
        padding-top: 1rem;
        padding-bottom: 3rem;
    }

    .SubmitFormWrap {
        padding-top: 1rem;
    }

    .SubmitForm {
        .company {
            text-transform: uppercase;
        }

        .selected {
            color: inherit;
        }
    }

    .finalPrice,
    .orderDetails {
        color: #555;
        padding-left: 1rem;
        padding-right: .5rem;
        font-size: 0.9rem;
        line-height: 1.1rem;
        padding-top: 0;
        margin-top: 0;
    }

    .orderDetails {
        padding-top: 1rem;
        padding-bottom: 2rem;
        padding-right: 2rem;

        .labelWrap {
            padding-left: 0;
            margin-bottom: 0;
        }

        .packageDetails {
            margin-top: 2rem;
        }

        .ProposalDetails {
            padding-top: 1rem;
            margin-left: -.6rem;
        }

        .MuiGrid-container {
            margin-left: -0.5rem;
        }

        p,
        .MuiGrid-root {
            padding-left: .5rem;

            &.title {
                padding-left: 0;
            }
        }

        svg {
            display: none;
        }

        .moreDetails {
            display: flex;
            align-items: center;

            // margin-top: 1rem;

            svg {
                display: inline;
                margin-right: .2rem;
            }
        }
    }

    .FAQWrapper,
    .SupportWrapper {
        // padding-bottom: 2rem;

        border-bottom: 1px solid #ccc;
        padding-bottom: 1rem;
        margin-bottom: 1rem;

        @media (min-width: 600px) {
            // .subtitle {
            //     max-width: 60%;
            // }
            // max-width: 60%;
            // padding-left: 2rem;
            // margin: 0 auto;
        }
    }

    .FAQWrapperText {
        .wrap {
            padding-left: 1.5rem;
            padding-right: 1rem;
        }

        .orderConsult {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
