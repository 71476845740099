.Cities {
    display: flex;
    flex-wrap: wrap;
    // padding-top: 1rem;

    // @media (min-width: 600px) {
    //     padding-top: 0;
    // }

    .City {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        justify-content: stretch;

        @media (min-width: 600px) {
            width: 50%;
        }
    
        .Wrap {
            text-decoration: none;

            // border: 1px solid #ccc;
            border-radius: 15px;

            width: 100%;

            padding: 1rem;

            margin-bottom: 1rem;

            overflow: hidden;
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;

            background-size: 100% auto!important;
            background-position: 0 50%!important;

            color: #fff;

            transition: background-size .5s ease-in-out;

            // @media (min-width: 600px) {
            //     height: 68px;
            // }

            &:before {
                display: block;
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: #000;
                opacity: .1;
            }

            &:hover:before {
                opacity: .2;
            }

            &:hover {
                background-size: 102% auto!important;
            }

            &.WrapInProgress {
                .city,
                .country {
                    color: #596a78;
                    text-shadow: none;
                }

                &:before {
                    background: #fff;
                    opacity: .8;
                }

                .mode {
                    display: block;
                    position: absolute;
                    right: 1rem;
                    top: 28px;
                    font-size: .8rem;
                    color: #fff;
                    background: rgb(28 169 220 / 90%);
                    text-shadow: 0 0 10px #00549a;
                    padding: .5rem 1rem;
                    border-radius: 15px;

                    @media (min-width: 600px) {
                        top: 36px;
                    }
                }

                &:hover {
                    background-size: 100% auto!important;
                }
            }

            .MuiSkeleton-rectangular {
                width: 60%!important;
            }

            .country .MuiSkeleton-rectangular {
                width: 90%!important;
            }

            .country {
                display: block;
                margin-top: -6px;
                text-shadow: 0 0 6px #000;;
                z-index: 1;
            }

            .city {
                display: block;
                font-size: 1.5rem;
                text-transform: uppercase;
                text-shadow: 0 0 10px #000;
                font-weight: 700;
                letter-spacing: 2px;
                z-index: 1;

                @media (min-width: 600px) {
                    font-size: 2rem;
                }
            }

            .promo {
                font-size: 1.2rem;
                text-align: left;
                display: flex;
                // color: #fff;
                font-weight: 400;
                letter-spacing: 3px;
                padding-left: 1rem;

                @media (min-width: 600px) {
                    font-size: 1.6rem;
                    text-align: center;
                    font-weight: 200;
                }                
            }
        }

        &.CityAdd {
            width: 100%;

            .Wrap {
                cursor: pointer;
                color: #333;

                &:before {
                    opacity: 0.02;
                }

                &:hover:before {
                    opacity: 0.05;
                }
            }

            @media (min-width: 600px) {
                width: 50%;
            }
        }

        @media (min-width: 600px) {
            &:nth-child(odd) {
                .Wrap {
                    margin-right: 2%;
                }
            }

            &:nth-child(even) {
                .Wrap {
                    margin-left: 2%;
                }
            }
        }

        span.Wrap {
            // background: #fafafa;
            // color: #fff;
        }

        .Wrap.Promo {
            // background: #e8f5ff;
            // border-color: #90cdff;
            // border: 0;

            display: flex;
            align-items: center;

            @media (max-width: 599px) {
                margin: 0;
            }

            .promo {
                svg {
                    position: relative;
                    top: 3px;
                }
            }
        }
    }
}
