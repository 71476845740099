.ShopingCartButton {
    white-space: nowrap;

    button {
        padding-top: 0;
        padding-bottom: 0;
        height: 56px;
    }

    &.selected {
        .MuiButtonBase-root {
            background: #fff;
            box-shadow: none;
            color: #000;
            outline: 1px solid #333;
        }
    }
}
