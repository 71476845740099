.LinksList {
    margin: 0;
    padding: 0;

    @media (min-width: 600px) {
        margin: 0;
        padding: 0;
        margin-bottom: -1rem;

        display: flex;
        flex-direction: row;
        align-items: stretch;
        flex-wrap: wrap;
    }

    li {
        list-style: none;
        padding: 0;

        &.no-description {
            a {
                align-items: center;
            }

            .icon {
                margin-top: 0;
            }
        }

        .item {
            display: flex;
            // box-sizing: content-box;
            box-sizing: border-box;
            align-items: center;
            box-sizing: border-box;
            height: 100%;
    
            text-decoration: none;

            padding: 1.5rem 1rem;
    
            position: relative;
            color: #000;

            @media (min-width: 600px) {
                padding: 1rem 1rem;

                border: 1px solid #ddd;
                border-radius: 15px;

                padding: 1rem 1rem;
            }
        }

        @media (min-width: 600px) {
            flex: 1 0 50%;

            &:nth-child(odd) {
                .item {
                    margin-right: 2%;
                }
            }

            &:nth-child(even) {
                .item {
                    margin-left: 2%;
                }
            }

            &:last-child {
                .item {
                    margin-right: 0;
                }
            }
        }

        @media (min-width: 600px) {
            margin-bottom: 1rem;
        }

        @media (max-width: 599px) {
            border-top: 1px solid #eee;
        }
    }
    
    @media (max-width: 599px) {
        margin-left: -1rem;
        margin-right: -1rem;
    }

    .icon {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        position: relative;

        font-size: 0;
        color: #fff;
        background: #333;
        border-radius: 50%;

        width: 40px;
        min-width: 40px;

        height: 40px;
        min-height: 40px;

        // margin-top: 5px;

        .notPublished {
            position: absolute;
            right: -6px;
            bottom: -6px;
            font-size: 15px;
            line-height: 18px;
            background: #fff;
            padding: 0;
            border-radius: 50%;
            // box-shadow: 0 -1px 10px #999;
            width: 26px;
            height: 26px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .photo {
        min-width: 60px;
        max-width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
    }

    .information {
        padding-left: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .title {
        width: 100%;;
        font-size: 20px;
        line-height: 22px;
    }

    .description {
        width: 100%;
        font-size: 14px;

        color: #777;

        p {
            margin: 0;
        }
    }

    .arrow {
        width: 3rem;
        height: 3rem;
        fill: #000;
        opacity: 0.1;
    }
}

.PreloaderLinksBlocks li:first-child,
body.main .LinksList li:first-child {
    border-top: 0;
}