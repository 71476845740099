.Breadcrumbs {
    z-index: 1;
    position: relative;

    &.MuiBreadcrumbs-root {
        a {
            color: #888;
            font-weight: 300;
        }
    }
}
