.NewsPage {
    .NewsPageCreated {
        padding: 0 1rem;
        color: #555;

        @media (min-width: 600px) {
            padding-left: 1.5rem;
            padding-right: 1rem;
        }
    }
}
