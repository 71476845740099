.ChatsTemplate {
    .chatName {
        display: flex;
        align-items: flex-start;

        svg {
            margin-right: .5rem;
        }
    }
    
    .LinksTileWithCategories .subtitle {
        padding-left: 0;
        margin-left: .2rem;
    }
}
