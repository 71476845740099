.Conversations {
    .FiltersLine {
        margin-bottom: 1rem;
    }

    .informer {
        margin-left: 0;
        margin-right: 0;
    }

    table.Table .td_logo {
        width: 66px;
        min-width: 66px;
        max-width: 66px;
    }

    .TableWrap table.Table td:first-child {
        padding-left: .5rem;
    }

    // .emptyConversations {
    //     opacity: .8;
    //     color: #333;
    //     padding: 5rem 1rem 6rem;
    //     // height: 100px;

    //     @media (max-width: 599px) {
    //         padding: 2rem 1rem 6rem;
    //     }
    // }
}
