.TopPagePicture {
    overflow: hidden;

    img {
        border-radius: 15px;
        max-width: 100%;
    }

    font-size: 0;
    margin-bottom: 1rem;

    &.CutHeightForDesktop {
        @media (min-width: 600px) {
            overflow: hidden;
            border-radius: 15px;
            height: 180px;
            display: flex;
            align-items: center;

            img {
                border-radius: 0!important;
            }
        }
    }
}
