.Insurance .ProposalDetails {
    .MuiGrid-item {
        // padding-top: 16px!important;
        // margin-bottom: -8px;
        display: flex;
    }

    .inline {
        display: inline!important;
    }
}

.ProposalDetailsBase {
    max-height: 450px;
    overflow: auto;

    .labelWrap.labelWrapRow .labelValue {
        display: flex;
        align-items: center;
        padding-top: .5rem;
    }
}
