.RequestTitle {
    font-size: 18px;
    font-style: italic;
    margin-top: -2px;

    .RequestTitleHistory {
        // vertical-align: middle;
        position: relative;
        top: -2px;

        .MuiSvgIcon-root {
            font-size: 18px;
        }
    }
}
