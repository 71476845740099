.Clinic {
    .banner {
        border-radius: 15px;
    }

    .region {
        .MuiChip-label,
        .MuiChip-root {
            padding: 0;
            background: none;
            border: 0;
            font-size: 1rem;
        }
    }
}
