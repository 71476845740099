.shoppingCartIconBadge {
    margin-left: 1rem;

    .MuiBadge-badge {
        margin-right: 10px;
        z-index: 1050;
    }
}


// .ShoppingDialog {
//     .MuiPaper-root {
//         width: 100%;
//         margin: 1rem;
//     }

//     .MuiDialogTitle-root {
//         padding-left: 1rem;
//         padding-right: 1rem;
//     }
// }

// .ShoppingDrawer {
//     .Cart {
//         overflow: auto;
//         margin-left: -1rem;
//         margin-right: -1rem;
//         padding-left: 1rem;
//         padding-right: 1rem;
//     }
// }

.Cart {
    @media (min-width: 600px) {
        width: 540px;
    }

    .MuiDialogContent-root {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .ShortOrderDescription {
        padding-bottom: 2rem;
    }

    .shopItem {
        position: relative;

        padding: 1rem 0;

        &:first-child:after {
            display: none;
        }

        &:after {
            content: "";
            display: block;
            height: 1px;
            width: 100%;
            background: #ddd;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    .shopTitle {
        color: #333;

        .titleWrap {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                display: flex;
                align-items: center;
                font-weight: 500;
                width: 100%;

                .Logo {
                    margin-right: 1rem;
                }

                .Clickable {
                    cursor: pointer;
                }

                .name {
                    cursor: pointer;
                }
            }
        }

        .icon {
            position: relative;
            top: 3px;
            right: .5rem;
            cursor: pointer;
        }

        .icon.up {
            display: none;
        }

        .icon.down {
            display: inline-block;
        }

        &.active {
            .icon.up {
                display: inline-block;
            }

            .icon.down {
                display: none;
            }
        }
    }

    .productsList {
        border-bottom: 1px solid #ccc;
        margin-bottom: 1rem;
        padding-left: 1rem;
        padding-top: 2rem;

        @media (min-width: 600px) {
            padding-left: 2rem;
        }

        .picture {
            float: left;
            margin-right: 1rem;
            width: 74px;
            height: 74px;
            border-radius: 15px;
        }

        .title {
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .finalPrice {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1rem;

        @media (min-width: 600px) {
            // justify-content: end;
        }

        .price {
            font-size: 0.8rem;

            .priceValue {
                font-size: 1.2rem;
            }

            @media (min-width: 600px) {
                padding-left: 1rem;

                .priceValue {
                    font-size: 1.3rem;
                }
            }
        }

        .button {
            @media (min-width: 600px) {
                margin-right: 1rem;
                margin-left: 2rem;
            }
        }
    }

    .product {
        position: relative;
        overflow: hidden;
        margin-bottom: 1rem;

        // .logo {
        //     float: left;

        //     width: 74px;
        //     height: 74px;
            
        //     margin-right: 1rem;
        //     margin-right: 1rem;
        // }

        .title {
            font-weight: 500;
        }

        .ShopProductPrice {
            font-size: 1rem;

            .oldPrice {
                font-size: .8rem;
            }
        }

        .oldPrice {
            text-decoration: line-through;
            color: #888;
            font-size: 1rem;
            margin-bottom: -.2rem;
        }

        .quantity {
            display: flex;
            align-items: center;

            width: 100%;
            padding-top: 2rem;

            .btn {
                margin: 0 1rem;
                cursor: pointer;
            }

            @media (max-width: 599px) {
                padding-left: 74px;
                top: -2.5rem;
                position: relative;

                .label {
                    display: none;
                }
            }

            @media (min-width: 600px) {
                padding: 0;
                position: absolute;
                right: 1rem;
                top: 0;
                width: auto;
            }
        }

        @media (min-width: 600px) {
            .title,
            .price {
                padding-right: 240px;
            }
        }
    }

    .FormContainerWrap {
        padding-bottom: 3rem;
        padding-top: 2rem;

        .editOrder {
            padding-top: 1rem;
        }
    }

    .Submit {
        margin-top: 2rem;
    }
}

.SwipeableDrawerContentWrapper {
    .Cart {
        margin-top: -1rem;
    }
}