.NetworkingTemplate {
    overflow: hidden;

    .photoWrap {
        margin-bottom: 2rem;
    }
    
    .thumbnail {
        float: none;

        @media (min-width: 600px) {
            margin-right: 1rem;

            width: 100%;
        }
    }

    .NetworkingDescription {
        margin-bottom: 2rem;
    
        p {
            margin-top: 0;
        }

        // @media (min-width: 600px) {
        //     margin-left: 280px;
        // }
    }

    
    .NetworkingInfo {
        margin-top: 2rem;

        @media (min-width: 600px) {
            max-width: 80%;
        }
    }

    .ContactCard {
        margin-bottom: 2rem;

        //.pseudoButton,
        .MuiButtonBase-root{
            margin-bottom: 1rem;
        }

        @media (min-width: 600px) {
            margin-bottom: 0;
            width: 240px;
            float: left;
            margin-right: 2rem;
        }
    }
}
