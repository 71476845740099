.Book {
    .labelWrap {
        align-items: start;
    }

    .goodreads {
        .max {
            opacity: .5;
        }
    }

    // TEMP
    // TODO: remove
    .notes {
        margin: 1rem 0;
    }


    .bookDetails {
        margin-bottom: 2rem;
        margin-left: 260px;
        padding-top: .5rem;

        @media (max-width: 599px) {
            margin-left: 0;
        }
    }
    // REMOVE AFTER RELEASE

    @media (max-width: 599px) {
        .bookDetails {
            .labelWrap {
                align-items: start;
            }
        }
    }

    @media (min-width: 600px) {
        .bookDetails {
            .labelWrap {
                padding-left: 0;
            }
        }
    }

    .actions {
        clear: both;
        margin-top: 2rem;
        margin-bottom: 2rem;

        @media (min-width: 600px) {
            clear: none;
            padding-left: 1rem;
        }

        .actionInfo {
            text-align: center;
            margin-bottom: .5rem;
            border: 1px solid #ccc;

            padding: 0 1rem;
            border-radius: 15px;

            @media (min-width: 600px) {
                padding-bottom: 0;
            }

            .text {
                padding-top: 1rem;
                padding-bottom: 1rem;

                p {
                    margin: 0;
                }
            }

            .MuiGrid-container {
                padding-bottom: 1rem;
            }

            &.reading {
                background: rgb(63, 180, 87, .8);
                color: #fff;
                border: 0;

                clear: both;

                box-sizing: border-box;

                // .label,
                // a {
                //     color: #fff;
                // }

                @media (min-width: 600px) {
                    clear: none;

                    width: 100%;
                    overflow: hidden;

                    .confirmed {
                        width: 280px; 
                        float: left;
                    }

                    .actions {
                        margin-left: 305px;

                        .MuiButtonBase-root {
                            margin-top: 2rem;
                        }
                    }
                    // max-width: 280px;
                }
            }

            &.booked,
            &.return,
            &.reading.overdue {
                background: rgb(211, 47, 47, .5);
                color: #fff;
                border: 0;
            }

            &.reserved {
                background: rgb(250, 223, 178, .5);
                color: #111;
                border: 0;
            }

            &.info {
                background: rgba(0, 0, 0, 0.05);
                border: 0;
            }

            &.reading,
            &.booked,
            &.return,
            &.reserved,
            &.info {
                .MuiButton-root {
                    border: 0;
                }
            }

            @media (min-width: 600px) {
                padding-left: 1rem;
                padding-right: 1rem;
            }

            .MuiButtonBase-root {
                // margin-top: .5rem;

                @media (max-width: 599px) {
                    // margin-bottom: -1rem;
                }
            }
        }

        .MuiButton-root.MuiButton-sizeSmall {
            width: 100%;

            padding-left: 1rem;
            padding-right: 1rem;

            @media (min-width: 600px) {
                width: auto;
            }
        }
    }

    @media (min-width: 600px) {
        .actionLink {
            margin-left: 1rem;
        }
    }

    .posterWrap {
        float: left;
        width: 140px;

        margin-bottom: 1rem;
        margin-left: 1rem;

        @media (min-width: 600px) {
            width: 220px;
        }
    }

    .infoBlock,
    .links,
    .description {
        clear: both;

        @media (min-width: 600px) {
            clear: none;
            margin-left: 260px;

            .CKEditorContent  {
                padding-left: .5rem;
                padding-right: 2rem;
            }
        }
    }

    .infoBlock,
    .links {
        margin-bottom: 2rem;
    }

    .details {
        margin-left: 180px;

        @media (min-width: 600px) {    
            padding-top: .5rem;
            margin-left: 240px;
            margin-bottom: 1rem;
        }

        .labelWrap {
            display: block;

            .label {
                display: block;
            }

            @media (max-width: 599px) {
                padding-left: 0;
            }

            @media (min-width: 600px) {
                display: flex;

                .label {
                    display: inline-block;
                }
            }   
        }
    }

    .poster {
        width: 100%;
        border-radius: 15px;
    }

    .additionalActions {
        @media (min-width: 600px) {
            margin-left: 240px;

            .MuiButton-root {
                width: auto;
            }
        }
    }

    .genre.labelWrapRow {
        .labelValue {
            .MuiChip-root {
                margin-top: .5rem;
            }
        }
    }
}

body.mobile {
    .genre {
        .labelValue {
            display: inline;

            .MuiChip-root {
                margin-top: .5rem;
            }
        }
    }
}

.BookDialog {
    .section {
        margin-bottom: 3rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .sectionIcon {
        font-size: 2rem;
        margin-right: 1.5rem;
    }

    .step {
        font-size: 1.3rem;
        margin-bottom: 1rem;
        font-weight: 300;

        display: flex;
        align-items: flex-start;
        vertical-align: top;

        .text {
            text-align: left;
            margin-top: -3px;
        }

        svg {
            margin-right: .5rem;
        }

        .title {
            opacity: .6;
            margin-right: .5rem;
        }

        &.success {
            color: #306f26;
            padding: .5rem;
            text-align: center;
        }
    }

    .ruleText {
        margin-bottom: 1.5rem;
    }

    // .submit {
    //     padding-top: 3rem;
    // }

    .rulesLinkSection {
        margin: 2rem 0 3rem;
    }

    .rule {
        display: flex;
        align-items: center;

        font-size: 1rem;
        line-height: 1.5;
        padding: 0 2rem 0 1rem;

        margin-top: 0;
        margin-bottom: 1.5rem;
        
        li {
            margin-bottom: .5rem;
        }
    }

    .return {
        font-size: 1.3rem;
        font-weight: 300;
        line-height: 1.4;
        padding: 0 1rem;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .noReturns {
        margin-bottom: 2rem;
    }

    .messageToAuthor {
        margin-bottom: 1rem;
    }

    .deposit {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 2rem;
    }

    .contactOwner {
        margin-bottom: 2rem;
    }

    .monobankLink {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .textNotes {
        font-size: 12px;
        margin-top: -6px;
        margin-bottom: 2rem;
    }

    .depostInfo {
        text-align: center;
    }

    .depostChoise {
        text-align: center;
        margin: 0;
        margin-top: -.5rem;
        margin-bottom: -.5rem;
    }

    .info.depositAlert {
        font-size: 14px;
    }

    .contact {
        margin-bottom: 3rem;
    }

    .info {
        display: flex;
        align-items: start;

        margin: 1rem 0;
        padding-right: 2rem;

        color: rgb(148, 0, 0);

        &.alert {
            background: rgba(256, 0, 0, 0.1);
            color: #a00;
            padding: 1rem;
            border-radius: 15px;
            
            a {
                color: #700;
            }
        }

        svg {
            margin-right: 1rem;
        }

        p:first-of-type {
            padding-top: 0;
            margin-top: 0;
        }

        p:last-of-type {
            margin-bottom: 0;
        }

        .list {
            margin: 0;
            padding: 0;
            margin-left: 1rem;
        }
    }
}
