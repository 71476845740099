.MessengerField {
    position: relative;
    padding-top: 0;

    .MuiGrid-root.MuiGrid-item {
        padding-top: 12px!important;

        &.noMargin {
            padding-top: 0!important;
            // padding-bottom: 12px!important;
        }
    }

    .MuiGrid-root.MuiGrid-item.ContactLabel {
        position: absolute;
        left: 16px;
        top: -14px;
        background: #fff;
        margin: 0!important;
        padding: 0!important;
    }

    .ContactControlWrap {
        display: flex;
    }

    .ContactMessenger {
        width: 70px;
        min-width: 70px;
        max-width: 70px;
        margin-right: .5rem;
    }

    .addSign {
        margin-top: 1rem;
        font-size: 0.9rem;
    }

    .fieldNotes {
        margin-top: .5rem;

        p {
            margin-bottom: 0;
        }
    }

}

.messengerOption {
    width: 100%;
    height: 24px;
    font-size: 0;
    text-align: center;
}
