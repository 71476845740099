.FAQ {
    .FAQSection {
        margin-bottom: 2rem;

        ul, li {
            margin-left: 0;
            padding-left: 0;
            padding-top: 0;
            margin-top: 0;
        }

        // p {
        //     padding-top: 0;
        //     margin-top: 0;
        // }

        ul {
            padding-left: 1.5rem;
        }
    }
}
