.Product {
    .photo {
        width: 100%;
        border-radius: 15px;
    }

    .ShopProductPrice {
        font-size: 2rem;
        margin-top: -1rem;

        .oldPrice {
            font-size: 1rem;
        }
    }
}
