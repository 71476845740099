.childrensList {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: -1rem;

    padding: 0;

    line-height: 2.2rem;

    .itemSkeleton {
        position: relative;
        top: 2px;
        display: inline-block;
        margin-left: 0rem;
        margin-top: 0rem;
        margin-bottom: 0.1rem;
    }

    li {
        position: relative;
        padding: 0;
        margin: 0;
        list-style: none;

        .wrapLink {
            display: flex;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
            padding-left: .5rem;

            @media (min-width: 600px) {
                padding-left: 0;
            }
        }

        .wrap {
            display: block;
            box-sizing: border-box;
            width: 100%;
            height: 100%;

            // &.skeleton {
            //     padding: 0 1rem;
            // }

            padding: 0 1rem;

            .link {
                position: relative;

                display: flex;
                align-items: center;

                text-decoration: none;

                box-sizing: border-box;    
                padding: 1.3rem 1rem;
                padding-left: 0;
                padding-right: 3rem;
                font-size: 20px;
                line-height: 1.2;

                width: 100%;

                .title {
                    font-size: 20px;
                    line-height: 22px;
                }

                .description {
                    display: block;
                    font-size: 14px;
                    color: #777;
                }

                @media (min-width: 600px) {
                    padding-left: 0;
                }
            }

            &.noLink {
                opacity: .5;
            }
            // &:hover {
            //     text-decoration: underline;
            // }

            // padding-bottom: 1rem;

            @media (min-width: 600px) {
                padding-bottom: 0;
            }
        }

        .icon {
            font-size: 0;
            color: #fff;
            background: #333;
            border-radius: 50%;
            width: 40px;
            min-width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1rem;
        }

        .arrow {
            width: 3rem;
            height: 3rem;
            fill: #000;
            opacity: 0.1;

            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &:after {
            content: "";
            display: block;
            background: #e5e5e5;
            height: 1px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom: -1px;
        }

        &:last-child:after {
            content: none;
        }

        // &:last-child {
        //     margin-bottom: 0;
        // }
    }

    @media (min-width: 600px) {
        display: flex;
        flex-wrap: wrap;
        border: 0;

        margin: 0;
        padding: 0;

        li {
            width: 50%;
            margin-bottom: 1%;


            &:nth-child(odd) {
                .wrap {
                    margin-right: 1%;
                }
            }

            &:nth-child(even) {
                .wrap {
                    margin-left: 1%;
                }
            }

            .wrap {
                display: flex;
                box-sizing: border-box;

                align-items: center;

                width: calc(100% - 1%);
                
                border: 1px solid #ddd;
                border-radius: 15px;

                &.skeleton {
                    border-color: #ddd;
                }
            }

            &::after {
                display: none;
            }
        }
    }
}

.Breadcrumbs + .childrensList {
    @media (max-width: 599px) {
        border-top: 1px solid #e5e5e5;
        margin-top: 0;
    }
}
