.SectionTileTemplate {
    @media (max-width: 599px) {
        .Breadcrumbs + .childrensList {
            border-top: 0;
        }

        .PreloaderText {
            display: none;
        }

        .childrensList {
            margin: 0 -.5rem;
            display: flex;
            flex-wrap: wrap;

            li {
                border: 0;
                width: 50%;
                display: inline-block;
                box-sizing: border-box;
                padding: 0 .5rem;
                padding-bottom: 1rem;

                &:after {
                    display: none;
                }

                .arrow {
                    // display: none;
                    path {
                        display: none;
                    }
                }

                .icon {
                    margin: 0;
                    margin-bottom: .5rem;
                }

                .wrap {
                    padding: 0;
                    display: flex;
                    align-items: stretch;
                    position: relative;
                }

                .wrapLink {
                    position: relative;
                    flex-direction: column-reverse;
                    padding: 0;
                }

                .InlineActions {
                    position: absolute;
                    right: 0;
                    top: 3px;
                    width: 100%;
                    justify-content: end;

                    &.InlineActionsOpen {
                        margin-top: -4rem;
                    }

                    .actions {
                        background: rgba(255,255,255,.5);
                        right: 0;
                        top: 50px;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        padding: 0 1rem;
                        box-sizing: border-box;

                        .action {
                            box-sizing: border-box;
                            position: relative;
                            width: 50%;
                            height: 0;
                            padding-bottom: 50%;
                            margin: 0;

                            .MuiSvgIcon-root {
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                box-sizing: border-box;
                                width: 70%;
                                height: 70%;
                            }
                        }
                    }
                }

                .link {
                    border: 1px solid #eee;
                    border-radius: 15px;
                    height: 100%;
                    // min-height: 80px;
                    flex-direction: column;
                    padding: 1rem;

                    .information {
                        // align-items: center;
                        margin: 0;
                        padding: 0;
                    }

                    .title,
                    .description {
                        text-align: center;
                        display: block;
                        width: 100%;
                    }

                    .title {
                        font-size: 16px;
                        line-height: 20px;
                    }

                    .description {
                        margin-top: 4px;
                        line-height: 16px;
                    }

                    // .MuiSkeleton-rectangular {
                    //     margin: 0 auto;
                    // }

                    .MuiSkeleton-rectangular {
                        width: 80%!important;
                    }
                }
            }
        }

        .PreloaderLinks {
            .arrow {
                position: relative;
                background: #555;
                border-radius: 50%;
                right: auto;
                top: auto;
                transform: none;
                margin-bottom: .5rem;

                path {
                    display: none;
                }
            }

            li .link {
                flex-direction: column-reverse;
            }
        }

        .notPublished {
            // position: absolute;
            // left: 5px;
            // top: 5px;
            font-size: 27px;
            line-height: 20px;
            margin-right: 0.5rem;
            position: relative;
            top: -36px;
            display: block;
        }

        .notPublishedWrap {
            .link {
                // min-height: 140px;
                padding-bottom: 50px!important;
            }
        }
    }    
}
