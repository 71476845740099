
// medium-zoom
.medium-zoom-image--opened {
    z-index: 1300;
    // border-radius: 25px;
}

.medium-zoom-overlay {
    z-index: 1299;
}

.medium-zoom-image {
    cursor: default;
    border: 3px solid #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px #333;
}

.medium-zoom-overlay {
  background: none!important;
}

.zoomTextWrapper {
  position: relative;
  display: block;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
    opacity: 0;
  }
}
