.CalendarWidgetTitle {
    .CalendarTitle {
        font-size: 1.5rem;
        font-weight: 400;
        padding-left: 1rem;
        padding-top: 1rem;
    }

    .CalendarClose {
        cursor: pointer;
        position: absolute;
        right: 1rem;
        top: 1.5rem;
    }
}
