.NodeLinesCompactTemplate {
    .LinksTileImageWrap {
        margin-bottom: 0;      
        overflow: hidden;
        display: flex;
        align-items: center;

        border-radius: 15px;

        height: 150px;

        @media (max-width: 599px) {
            height: 120px;
            margin-bottom: -.5rem;
        }
    }

    .LinksTileItemSkeleton {
        .title {
            display: none;
        }
    }

    .titleLink {
        display: inline-flex;
        // font-size: 1rem;
        align-items: center;
    }

    .description {
        display: block;
        text-align: left;
        padding-left: 8px;
        margin-bottom: 1rem;
        margin-top: -8px;
        opacity: 0.6;
    }

    .LinksTileImageLink {
        display: block;
        position: relative;
    }

    .description {
        display: none;
    }

    .LinksTileItem {
        position: relative;

        .title {
            position: absolute;
            bottom: .5rem;
            left: 42px;
            margin: 0;

            width: auto;
            // border-bottom-left-radius: 10px;
            // border-top-right-radius: 10px;
            border-radius: 10px;
            padding: 0.5rem 1.5rem 0.5rem 1rem;
            text-align: left;
            font-size: 18px;

            // background: #0d0d0dd1;
            // box-shadow: 3px -4px 5px #6195c2;
            // color: #fff;

            background: rgba(255, 255, 255, .9);
            color: #333;

            @media (max-width: 599px) {
                bottom: 0;
            }
        }

        .MuiIcon-root {
            margin-right: 0.5rem;
        }
    }
}
