.ProfileUsersPage {
    .blocked td {
        background: #ffe2e2!important;
    }

    .not_checked td {
        background: #fff8e2!important;
    }

    // .Details {
    //     .label {
    //         min-width: 110px;
    //     }
    // }

    .td_image {
        font-size: 0;
        width: 80px;
    }
}
