.AdminContentPage {
    .TableWrap {
        margin-bottom: 2rem;
    }

    .Details {
        .labelWrap {
            @media (min-width: 600px) {
                padding-left: 8rem!important;
            }
        }
    }

    .ContentAdminPanel {
        padding: 2rem 1rem 1rem;
    }
}
