.HistoryUserAdminPage {
    .UserInfo {
        margin-bottom: 2rem;
        
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (min-width: 600px) {
            margin-bottom: 3rem;
        }

        .labelWrap:last-child {
            margin-bottom: 0;
        }

        .AvatarWrap {
            width: 70px;
            margin-right: 1rem;
        }

        .Details {
            width: calc(100% - 70px);
        }
    }

    .textItem {
        margin-right: .5rem;
    }


    .labelWrap.details {
        display: block;
    //     align-items: start;
    //     .label {
    //     }
    //     .labelValue {
    //         padding-top: .2rem;
    //     }
    }
}

.HistoryUserAdminPageDetails {
    padding-bottom: 1rem;
}
