.pageTop {
    .subheader {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        @media (max-width: 599px) {
            margin-bottom: .5rem;
            margin-top: .2rem;
        }

        .logo {
            position: relative;
            text-decoration: none;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: #fff;
            // font-weight: bold;
            letter-spacing: 2px;

            font-size: 1.1rem;
            font-weight: 300;
        }

        .iconsSet {
            font-size: 0;
            margin-left: 1rem;
            position: relative;

            @media (max-width: 599px) {
                // position: absolute;
                // left: 50%;
                // top: 0;
                // transform: translate(-50%, 15px);
            }
        }

        .cityName {
            display: inline-flex;
            align-items: center;

            white-space: nowrap;
            
            background: rgba(255, 255, 255, 0.8);
            border-radius: 10px;
            color: #002020;

            padding-left: .5rem;
            padding-right: .5rem;

            font-weight: 400;
            font-size: 16px;

            height: 34px;

            text-decoration: none;

            // @media (min-width: 600px) {
                // margin-left: 1rem;
                // margin-right: 1rem;
            // }

            background: rgba(255, 255, 255, 0.4);
            transition: background-color .5s ease-in-out;

            .MuiSvgIcon-root {
                transition: color .5s ease-in-out;
                color: #71a2d2!important;
            }

            &:hover {
                background: rgba(255, 255, 255, 0.7);

                .MuiSvgIcon-root {
                    color: #50789f!important;
                }
            }

            @media (min-width: 600px) {
                background: rgba(255, 255, 255, 0.3);

                // position: absolute;
                // left: 126px;
                padding-left: 5px;
                padding-right: 5px;
                height: 28px;

                // .MuiSvgIcon-root {
                //     color: #cfe7ff!important;
                // }
            }

            &:hover {
                text-decoration: none;
            }

            .flag {
                margin-right: 5px;
                margin-left: 3px;
            }

            .city {
                font-size: 0;
                margin: 0 -5px;
            }

            .MuiSvgIcon-root {
                margin-left: 5px;
                margin-right: -3px;
                color: #333;
            }

            // &::before {
            //     content: '';
            //     display: block;
            //     border-left: 1px solid #fff;
            //     height: 16px;
            //     width: 1px;
            //     position: absolute;
            //     left: 0;
            //     top: 2px;
            //     opacity: .8;
            // }

            // &::after {
            //     content: '';
            //     display: block;
            //     border-left: 1px solid #fff;
            //     height: 16px;
            //     width: 1px;
            //     position: absolute;
            //     left: 100%;
            //     margin-left: 5px;
            //     top: 2px;
            //     opacity: .8;
            // }
        }

        .logoImg {
            margin-right: .5rem;
            width: 30px;
            height: 30px;
        }

        .beta {
            background: #f02424;
            border-radius: 5px;
            color: #fff;
            font-size: 6px;
            padding: 1px 0 1px 4px;
            position: absolute;
            right: -18px;
            top: -5px;
            transform: rotate(0deg);
            font-weight: 500;
        }

        .separator {
            display: none;
            margin: 0 .4rem;
            color: #fff;
            font-size: 16px;
            // font-weight: bold;
        }
    }

    .subheaderWrap {
        display: flex;
        align-items: center;

        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            
            @media (min-width: 600px) {
                left: -5px;
            }

            .back {
                position: relative;
                top: 3px;
                margin-right: .2rem;
            }
        }
    }

    // .location {
    //     display: none;

    //     color: #fff;
    //     background: rgba($color: #000, $alpha: 0.2);
    //     padding: 2px 10px;
    //     padding-right: 0;
    //     border-radius: 5px;
    //     font-size: 12px;
    //     margin-right: 1rem;
    //     cursor: pointer;

    //     .name {
    //         vertical-align: middle;
    //     }

    //     .arrow {
    //         margin-left: 2px;
    //         margin-right: 2px;
    //         vertical-align: middle;
    //     }
    // }

    .user {
        display: flex;
        align-items: center;
    }
}

// .goBackToMain {
//     // padding-top: 1rem;
//     // padding-bottom: 1rem;
//     // padding-left: 1rem;
//     // padding-right: 1rem;
//     // border-bottom: 1px solid #ccc;
//     // margin-bottom: 1rem;
//     // margin-left: -1rem;
//     // margin-right: -1rem;
//     // /* margin-top: -1rem; */

//     // a {
//     //     display: flex;
//     //     align-items: center;
//     //     color: #1975d2;
//     // }
// }


// @media (max-width: 599px) {
//     // .goBackToMain {
//     //     /* display: none; */
//     //     margin-top: -1rem;
//     // }
// }
