.MuiDayCalendar-monthContainer {
    padding-top: .5rem;
    padding-bottom: .5rem;

    .MuiBadge-badge {
        // transform: translateY(2px);
        // margin-top: 2px;
        // margin-right: 2px;
        top: 4px;
        right: 8px;
        font-size: 8px;
        padding: 0;
        min-width: 14px;
        height: 14px;
        background: #3c91dd;
    }
}

.MuiSkeleton-rectangular {
    border-radius: 15px;
}

.MuiAccordion-root.MuiPaper-root {
    margin-bottom: 1rem;
    border: 1px solid #aaa;
    box-shadow: none;
    // background: rgb(68,178,252);
    // background: linear-gradient(38deg, rgb(216, 237, 251) 0%, rgba(217,239,254,1) 80%, rgb(229, 244, 255) 100%);

    &::before {
        display: none;
    }

    &:first-of-type,
    &:last-of-type {
        border-radius: 20px;
    }

    .MuiPaper-root.MuiPaper-rounded::before {
        display: none;
    }

    .MuiPaper-root:last-of-type,
    .MuiAccordion-root:first-of-type {
        border-radius: 20px;
    }

    .MuiTypography-root {
        padding-right: 1rem;
    }

    .MuiAccordionSummary-content p {
        line-height: 1.2;

        &:first-child {
            margin-top: 0;
        }
    }

    .MuiAccordionDetails-root p:first-of-type{
        margin-top: 0;
    }

    .MuiAccordionSummary-root.Mui-expanded {
        min-height: auto;
    }

    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0;
    }

    .MuiBadge-badge {
        position: relative;
        background: #333;
        color: #fff;
        transform: none;
        margin-left: .5rem;
    }

    .Mui-expanded .MuiBadge-badge {
        transform: rotate(180deg);
        margin-left: 0;
        position: absolute;
        top: 10px;
    }
}

.MuiTooltip-popper {
    background-color: transparent!important;
    border-radius: 0!important;

    .MuiTooltip-tooltip {
        margin-bottom: .5rem!important;
    }
}

.MuiPickersCalendarHeader-label::first-letter {
    text-transform: uppercase;
}

.MuiPaper-root.MuiPaper-rounded {
    .MuiMenuItem-root.Mui-focusVisible {
        border-radius: 10px;
        background: none;
    }
}

@media (max-width: 599px) {
    .MuiMultiSectionDigitalClock-root .MuiMultiSectionDigitalClock-root {
        overflow: auto;
    }
}

.MuiBreadcrumbs-ol {
    .MuiBreadcrumbs-li {
        margin-right: 8px;
    }

    .MuiBreadcrumbs-separator {
        margin-left: 0;
    }
}

.MuiInputLabel-root {
    width: calc(100% - 40px);
    text-align: left;
    color: #777!important;

    &.Mui-error {
        color: #d32f2f!important;
    }

    &.MuiInputLabel-shrink {
        width: auto;
    }
}

.MuiPickersLayout-contentWrapper {
	.MuiDateCalendar-root {
		.MuiPickersLayout-root,
        .MuiPickersSlideTransition-root {
            min-height: 260px;
        }
	}
}

// disable bacdrop for select
#menu-,
.MuiBackdrop-invisible {
    background: none;
    // display: none!important;
}

.MuiToggleButtonGroup-root {
    .MuiButtonBase-root {
        border-top: 0;
        border-bottom: 0;
        padding: 8px;
        color: #111;

        &.Mui-selected,
        &.Mui-selected:hover {
            color: #555;
            background-color: rgba(0, 0, 0, 0.03);
        }

        &:first-child {
            border-left: 0;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:last-child {
            border-right: 0;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}

.MuiChip-root {
    &.textOnly,
    &.textOnly .MuiChip-label {
        display: inline;
        background: none;
        border: 0;
        font-size: 1rem;

        padding: 0!important;
        margin: 0!important;

        height: auto;
    }
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    max-width: 180px;
}

// buttons
.emulateButton {
    display: inline-block;
    border: 1px solid #a2a2a2;
    color: #111;
    background-color: #ffffff;
    padding: 1.2rem 1.5rem;
    border-radius: 15px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;

    &.small {
        font-size: 13px;
        padding: 10px;
        border-radius: 10px;
        font-weight: 500;
    }
}

.MuiButton-root.errorButton,
.pseudoButton.errorButton {
    border-color: rgb(211, 47, 47)!important;
    color: rgb(211, 47, 47)!important;
}

.MuiButton-root.successButton,
.pseudoButton.successButton {
    border-color: rgb(6 132 32)!important;
    color: rgb(6 132 32)!important;
}

// labels
.errorLabel {
    background: rgb(211, 47, 47)!important;
    border-color: rgb(211, 47, 47)!important;
    color: #fff!important;
}

.MuiPaper-root.MuiSnackbarContent-root {
    background-color: rgba(5,5,5,.9);
    color: #fff;
    border-radius: 15px;

    .MuiSnackbarContent-message {
        padding-top: 2px;
        padding-bottom: 2px;
    }
}
