.HistoryAdminPage {
    .FiltersLine {
        margin-bottom: 1rem;
    }

    .filterButtons {
        margin-right: 3rem;
    }

    // .labelWrap.details {
    //     align-items: start;
    //     .label {
    //     }
    //     .labelValue {
    //         padding-top: .2rem;
    //     }
    // }
}

.tr_error td,
.tr_node_worker_remove td,
.tr_node_remove td,
.tr_node_remove_file td,
.tr_node_remove_not_used_files td,
.tr_history_flush td,
.tr_history_content_flush td,
.tr_history_user_flush td,
.tr_history_content_clean td,
.tr_history_user_clean td,
.tr_notify_flush td,
.tr_user_block td
{
    background: #ffe2e2!important;
}

.tr_node_worker_restore td,
.tr_node_restore td {
    background: #e3f1e6!important;
}
