.CKEditor {
    position: relative;
    overflow: hidden;

    .PreloaderWrap {
        z-index: 99;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, .6);
    }

    .ck-sticky-panel__content {
        &:before {
            content: '';
            position: absolute;
            left: -2px;
            top: -4px;
            height: 30px;
            width: calc(100% + 4px);
            background: #fff;
            z-index: calc(var(--ck-z-modal) - 1);
        }

        .ck-toolbar {
            border-top-left-radius: 15px!important;
            border-top-right-radius: 15px!important;
            background: #333!important;
            margin-left: -2px;
            margin-right: -2px;
            margin-top: -2px;

            position: relative;
            z-index: var(--ck-z-modal);
        }
    }

    &.error {
        .ck-editor,
        .ck-toolbar {
            border-color: rgb(211, 47, 47)!important;
        }
    }

    .ck.ck-editor {
        font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
        border: 1px solid #a2a2a2;
        border-radius: 20px;

        // .ck-toolbar,
        .ck-content,
        .ck-focused {
            border: 0!important;
            background: none!important;
            box-shadow: none!important;
        }

        .ck-toolbar__grouped-dropdown {
            .ck-dropdown__button {
                // border-radius: 50%;
            }
        }

        .ck-button {
            color: #fff;
            border-radius: 50%;
        }

        .ck-dropdown__panel {
            border: 0;
            border-radius: 10px;
            padding: .5rem;
            box-shadow: 5px 5px 10px rgba(0,0,0,.5);
        }

        .ck-content .image {
            margin: .9em 0;
        }

        .ck-sticky-panel__content {
            background: #333333;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;

            .ck-list__item {
                background: #333333;
            }

            .ck-button {
                color: #fff;
                // border-radius: 0;

                &.ck-button_with-text,
                &.ck-dropdown__button {
                    border-radius: 10px;
                }

                &.ck-on,
                &:hover {
                    background: #555555!important;
                }
            }

            .ck-dropdown {
                color: #fff;

                &__panel {
                    background: #333333;
                }
            }
        }
    }

    .ck-editor__editable_inline {
        min-height: 112px;
    }

    .disabledLabel {
        position: absolute;
        color: #aaa;
        left: 14px;
        top: 50px;
        // transform: translate(-50%, 0);
    }

    &.noPanel {
        .ck-sticky-panel__content {
            &:before {
                display: none;
            }
        }    
    }
}

.ck.ck-balloon-panel {
    z-index: 1400 !important; // Put a higher value that your MUI Dialog (generaly 1300)
}

.CKEditor.disabled {
    .ck.ck-editor {
        color: #aaa!important;    // rgba(0, 0, 0, 0.38)
        border: 1px solid #eaeaea!important;
        background-color: #f9f9f9!important;
    }

    .disabledLabel {
        top: 17px;
    }

    .ck-placeholder {
        display: none;
    }
}
