.ShopCategoryLabel {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 300;
    padding-bottom: .5rem;

    .img {
        width: 32px;
        height: 32px;
        margin-right: .5rem;
    }
}