.MuiFormControlLabel-root.InsuranceSport {
  /* align-items: flex-start;
  padding-top: .4rem;

  .MuiCheckbox-root {
    padding-top: 0;
    padding-left: 1.3rem;
  }

  .MuiFormControlLabel-label {
    line-height: 1.1;
    font-size: .9rem;
  } */

  @media (min-width: 600px) {
    margin-top: .5rem;
    margin-left: 1rem;
  }
}
