body {
  background: #fff;

  @media (min-width: 600px) {
      background: rgb(52,106,156);
      background: linear-gradient(77deg, rgba(52,106,156,1) 0%, rgba(105,183,255,1) 60%, rgba(0,212,255,1) 100%);
      background-attachment: fixed;
  }
}

.pageTop {
  background: rgb(52,106,156);
  background: linear-gradient(77deg, rgba(52,106,156,1) 0%, rgba(105,183,255,1) 60%, rgba(0,212,255,1) 100%);

  padding: .5rem 0;

  padding-bottom: 1rem;
  margin-bottom: -1rem;

  .subheader {
    height: 34px;
    padding: 0 .5rem;
  }

  @media (min-width: 600px) {
      background: none;

      padding: 1rem 0;
      padding-bottom: 0;
      margin-bottom: 0;

      width: calc(900px + 4rem);
      
      margin-left: auto;
      margin-right: auto;

      .subheader {
        padding: 0 1rem;
      }
      
      .Banner {
        width: 100%;
      }
  }
}

.BannerCarousel.MainBannerCarousell {
  // height: 200px;
  // margin-bottom: 2.8rem;

  .Banner {
    .BannerImg {
      height: 200px;
    }
  }

  @media (max-width: 599px) {
    .swiper-autoheight .swiper-wrapper {
      margin-top: .5rem;
    }

    .Carousel .swiper-pagination {
      margin-top: .7rem;
    }
  }
}

.contentWrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  // max-width: 599px;
  // margin: 0 auto;
  // background: #fff;

  // padding-bottom: 4rem!important;
  // padding-top: 32px;
  // padding-left: 16px;
  // padding-right: 16px;
  // box-sizing: border-box;
}

.Content {
  .globalInformer {
    text-align: center;
    background: rgb(63 180 87);
    color: #fff;
    margin: .5rem;
    padding: .5rem 1rem;
    border-radius: 15px;
    line-height: 1.2;
    
    text-align: left;

    display: flex;
    align-items: center;

    &.error {
      background: rgba(230, 26, 26, 0.98);
    }

    svg {
      margin-right: .5rem;
    }

    .link {
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    a {
      color: #fff;
    }
  }

  .globalInformer {
    margin: 0;
    margin-bottom: 1rem;
    margin-left: -.5rem;
    margin-right: -.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}