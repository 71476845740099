.FilterControlItem {
    display: flex;
    align-items: center;
    min-width: calc(100% - 2rem);
    overflow: hidden;

    .icon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }

    .MuiCheckbox-root {
        padding-left: 0;
    }

    .text {
        margin-left: .5rem;
    }
}

.FilterFieldSaveButton {
    display: flex;
    padding: .5rem 0 0;

    .MuiButton-root {
        margin: 0 .5rem;
    }
}

.FilterFieldTitle {
    position: relative;
    font-weight: bold;
    font-size: 1rem;
    padding: .5rem 1rem;

    .FilterFieldTitleSearch {
        position: absolute;
        right: 1rem;
        top: .5rem;
        cursor: pointer;
    }

    .FilterFieldTitleInput {
        height: 56px;

        .FilterFieldTitleInputWrap {
            padding-top: .8rem;
        }
    }
}

.FilterFieldValues {
    overflow: auto;
    max-height: 50vh;

    &.FilterFieldValuesOpen {
        max-height: calc(50vh - 56px);
    }

    .FilterFieldValuesList {
        margin: 0;
        padding: 0;
    }

    .MuiMenuItem-root.selected > .MuiTouchRipple-root {
        background: rgba(25, 118, 210, 0.08);
    }    
}

// THIS CODE FIX ISSUE WITH VIRTUAL KEYBOARD ON IOS - mobile and embeded
body.mobile {
    .FilterFieldVerticalMenuPopover {
        display: flex;
        align-items: start;
        top: 2vh;

        .MuiPaper-root {
            top: auto!important;
            max-height: none!important;
        }
    }

    .FilterFieldValuesHasFilter {
        max-height: 68vh;

        &.FilterFieldValuesOpen {
            max-height: calc(32vh);
        }

        .FilterFieldValuesList {
            min-height: calc(68vh - 56px);
        }
    }
}

.FilterChips {
    width: 100%;
    white-space: nowrap;

    .MuiChip-root,
    .MuiChip-root:hover,
    .Mui-focused {
        margin-right: .5rem;
        background: none!important;
    }
}

.FilterFieldMulti {
    .MuiBox-root {
        display: block;
        overflow: hidden;
        margin-top: -5px;

        margin-bottom: -5px;
        margin-left: -10px;

        @media (max-width: 599px) {
            margin-top: -10px;
            margin-bottom: -10px;
        }
    }

    .MuiChip-root {
        max-width: 80%;
        justify-content: start;

        border: 1px solid #bdbdbd;
        background: #fff;
        border-radius: 16px;

        margin-left: 3px;
        margin-right: 3px;
    }

    .MuiChip-root {
        @media (max-width: 599px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .MuiSvgIcon-root {
        box-shadow: -10px 0 5px #fff;

        &.MuiSelect-iconOpen {
            box-shadow: 10px 0 5px #fff;
        }
    }
}

.MuiInputBase-sizeSmall {
    .MuiBox-root {
        margin-top: 0;
    }

    .MuiChip-root {
        font-size: 12px;
        height: 24px;
    }
}
