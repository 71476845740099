.SwipeableDrawerContainer {
    .MuiDrawer-paper {
        max-height: calc(100vh - 1rem);
        overflow: visible;
    }

    .SwipeableDrawerContentWrapper {
        position: relative;
        max-height: 450px;
        overflow: auto;

        padding: 1rem 1rem 3rem 1rem ;

        &.noTitle {
            padding-top: 2rem;
        }
    }
}

.SwipeableDrawerContentWrapper {
    .progressBlock {
        width: calc(100vh - 180px);
        height: 260px;
        margin-top: -6rem;
    
        .preloaderWrap {
            margin-top: -2rem;
        }
    }
}

.DialogContentWrapper {
    .FormSubmitButton {
        position: relative!important;
        margin: 0!important;
        top: auto!important;
        padding: 0!important;
    }
}

.DialogContentWrapper {
    min-width: 320px;
    padding: 0rem 0 1rem;

    @media (min-width: 600px) {
        width: auto;
        padding-top: 12px;
        padding-bottom: 0;
    }
}

.SwipeableDrawerTitle,
.MuiDialogTitle-root {
    box-shadow: 0 10px 20px #fff;
    z-index: 1;
}

.DialogContentTitle,
.SwipeableDrawerTitle {
    position: relative;
    
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 2rem;

    padding: .5rem 4rem 1rem 1rem;

    display: flex;
    align-items: center;

    padding-bottom: 1rem;
    // border-bottom: 1px solid #ddd;

    // margin-bottom: -2rem;

    @media (min-width: 600px) {
        padding-right: 2rem;
    }

    .close {
        position: absolute;
        right: 0;
        top: 14px;
        cursor: pointer;
    }
}

.SwipeableDrawerTitle {
    padding-top: 1.5rem;

    svg {
        margin-right: 1rem;
        top: 31px!important;
    }
}

.DialogContentTitle {
    padding-left: 0;
    margin-bottom: -22px;
}
