.Event {
    .TopPagePicture {
        &.CutHeightForDesktop {
            @media (min-width: 600px) {
                height: 320px;
            }
        }
    }
    
    .actions {
        display: none;
    }

    .timezone {
        margin-top: -1rem;
        font-size: .8rem;
        color: #777;
    }

    .labels {
        .MuiChip-root {
            margin-right: .5rem;
            margin-bottom: .5rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .labelsWrap {
        padding-left: 1rem;
    }

    .Link {
        margin-bottom: 2rem;
    }

    .EventDetailsDesktop {
        position: relative;
        z-index: 1;

        margin-bottom: 2rem;
        // margin-top: -5.5rem;
    }

    .EventDetailsMobile {
        margin-top: -1rem;
        margin-bottom: 3rem;
    }

    .DesktopEmptyBlock {
        height: 3rem;
    }

    .dateDetails {
        display: flex;
        align-items: start;
    }

    .timezoneDetails {
        cursor: pointer;

        margin: 0;
        padding: 0;
        margin-left: .3rem;

        svg {
            width: 1rem;
            height: 1rem;
        }
    }

    @media (max-width: 599px) {
        .LocationWidget {
            margin-top: 3rem;
        }
    }
}
