.ErrorPage {
    padding: 5rem 2rem;

    .bcMessage {
        margin-top: 5rem;
    }

    .Breadcrumbs {        
        margin-top: 1rem;
        /* margin-bottom: 3rem; */
        /* font-size: 1.2rem; */
    }

    .status {
        font-size: 5rem;
        /* font-weight: bold; */
        margin-bottom: 1rem;
        color: #333;
    }

    .message {
        color: #555;
    }

    .icon {
        font-size: 100px;
        color: #8a021e;
        vertical-align: middle;
        margin-top: -16px;
        margin-right: -16px;
    }

    .pseudoButton {
        width: auto;
    }

    .actionsBox {
        margin-top: 2rem;
        margin-bottom: 3rem;
    }
}
