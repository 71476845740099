.LinksTemplate {
    .pageDescription {
        margin-top: 3rem;
    }

    .adminChildrensList {
        // background: #f7f7f7;
        border-radius: 15px;
        padding: 1rem;
        padding-bottom: 0;

        border: 1px solid #ddd;
        border-radius: 15px;

        margin-bottom: 2rem;

        @media (min-width: 600px) {
            margin-bottom: 0;
        }

        .label {
            font-style: normal;
            font-size: 12px;
        }

        .details {
            padding-left: 1rem;
            padding-right: 1rem;
            margin-bottom: 1rem;
            font-size: .8rem;
            opacity: .5;
        }

        .wrapLink {
            padding-left: 0;
        }

        a {
            color: #888;
        }
    }
}

.notInConfig {
    list-style: none;

    margin: 0 -1rem;
    padding: 0;
    margin-top: 0;

    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (min-width: 600px) {
        margin: 0;
        margin-top: 1rem;
        flex-direction: row;
    }

    li {
        margin: 0;
        padding: 0;

        width: 100%;

        .wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @media (min-width: 600px) {
            width: 49%;
            margin-right: 1%;
            display: inline-block;
            margin-bottom: 1rem;

            &:nth-child(even) {
                margin-left: 1%;
                margin-right: 0;
            }

            .wrap {
                background: #fff;
                border: 1px solid #ddd;
                border-radius: 15px;
            }
        }
    }

    li .wrap .link {
        padding-left: 0;
        font-size: 16px;

        .arrow {
            right: 0;
        }
    }
}

.LinksList + .adminChildrensList {
    margin-top: 3rem;
}
