// .ProfilePage.ProfileEditPage {
//     .userDetails .pageTopTitle {
//         margin-bottom: -1rem;
//     }
// }

.userEditForm {
    // padding-top: 2rem;

    .Form {
        margin-bottom: 1rem;
    }
}
