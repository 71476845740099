.TopicAvatar {
    font-size: 0;
    color: #fff;
    background: #333;
    border-radius: 10px;
    width: 60px;
    min-width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiIcon-root {
        font-size: 2rem;
    }
}
