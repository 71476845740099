.UserNodesList {
    margin-bottom: 1rem;

    i {
        opacity: .5;
    }

    .cityValues {
        margin-bottom: 1rem;
    }
}
