.Profile {
    .AvatarWrap {
        position: relative;
        float: right;
        margin-right: -10px;
        margin-top: -3.5rem;
        margin-bottom: -1rem;
        margin-left: 1rem;
        z-index: 1;

        .Avatar {
            width: 120px;
            height: 120px;
            min-width: 120px;
            min-height: 120px;
        }

        .edit {
            cursor: pointer;

            position: absolute;
            right: 10px;
            bottom: 5px;

            color: #fff;

            font-size: 0;

            border-radius: 50%;

            padding: .5rem;

            background: rgba(0,0,0,0.8);

            svg {
                width: 12px;
                height: 12px;
            }
        }
    }

    .pageTitle {
        font-size: 2rem;
        word-break: break-all;
    }

    .pageTopTitle {
        padding-bottom: .2rem;
    }

    .userEmail {
        color: #777;
        white-space: nowrap;
        overflow: hidden;
        padding-left: 1.4rem;
        // margin-bottom: 2rem;
    }

    .userDetails {
        position: relative;
        padding-bottom: 2rem;

        // .Breadcrumbs {
        //     margin-bottom: 2rem;
        // }

        .actionButtons {
            // margin-top: -32px;
            
            svg {
                margin-right: 1rem;
            }
        }
    }

    .SignInPage {
        @media (min-width: 600px) {
            max-width: 800px;
            margin: 0 auto;
            padding: 3rem;

            .MuiGrid-item {
                padding-top: 32px;
            }
        }

        .SignInPageTitle {
            font-size: 1.8rem;
            font-weight: 300;
            margin-bottom: 1rem;
        }
    }
}
