body {
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

body.mobile {
  background: #444;

  .pageTop {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 2px;

    .Carousel {
      .Banner {
        box-sizing: border-box;
        padding: 0 0.5rem;
      }
    }
  }

  .contentWrap::after {
    content: '';

    position: fixed;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 60%;

    background: #fff;
    z-index: 1;

    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    @media (orientation: landscape) {
      height: 30%;
    }
  }

  .AppWrap {
    position: relative;
    z-index: 2;
  }

  &.node_events .contentWrap::after {
    height: 20%;

    @media (orientation: landscape) {
      height: 0;
    }
  }

  &.node_events.view_list .contentWrap::after {
    height: 50%;
  }
}

a {
  // color: #1975d2;
  color: #333;
  text-decoration: underline;
  
  &:hover {
    text-decoration: none;
  }
}

pre {
  font-style: normal;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 100%;
  overflow: hidden;
  margin: 0;
  margin-bottom: 1rem;
  white-space: pre-wrap;
}

// CLASSES
// icon
.Icon {
  &.Telegram {
    color: #1fa0e1;
  }

  &.WhatsUp {
    color: #4caf50;
  }

  &.errorIcon {
    color: rgba(230, 26, 26, 0.98);
  }

  &.successIcon {
    color: rgba(4, 108, 4, 0.98);
  }
}

// logo
.Logo {
  border-radius: 50%;

  &.Logo32 {
    width: 32px;
    height: 32px;
  }

  &.Logo64 {
    width: 64px;
    height: 64px;
  }

  &.LogoLeft {
    float: left;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

// photos
.photoWrap {
  position: relative;

  .photoLabel {
    position: absolute;
    right: 5px;
    bottom: 5px;

    font-size: .8rem;

    max-width: calc(100% - 10px);

    .MuiChip-root {
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        border: 0;
        // padding-left: 15px;
        // padding-right: 15px;
    }
  }
}

img.thumbnail,
img.gallery {
  // border-radius: 50%;
  border-radius: 15px;
}

img.poster,
img.baner {
  border-radius: 15px;
}

// buttons
.pseudoButton {
  display: inline-flex;
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px solid #a2a2a2;
  color: #111;
  background-color: #ffffff;
  text-decoration: none;
  padding: 16px 1.5rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.nowrap {
  white-space: nowrap;
}

// link
.pseudoLink {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}


// labels
.labelWrap {
  display: flex;
  align-items: center;
  word-break: break-word;
  margin-bottom: 1rem;

  .label {
    margin-right: .5rem;
  }

  .labelValue {
    .MuiSvgIcon-root {
      margin-right: .5rem;
    }
  }

  &.labeWrapInline {
    margin-left: 0;
    padding-left: 0;
    display: inline;
    vertical-align: middle;

    .label, .labelValue {
      display: inline;
    }
  }

  &.labelWrapRow,
  // TODO: use specific parent 
  &.description {
    display: block;

    .label,
    .labelValue {
      display: block;
    }
  }

  &.labelWrapTop {
    align-items: start;
  }
}

.label {
  vertical-align: middle;
  text-wrap: nowrap;
  // text-wrap: balance;
  font-style: italic;
  color: #777;

  &.labelMultiline {
    text-wrap: balance;
  }
}

// span.label {
//   display: inline-block;
// }

.labelValue {
  display: flex;
  align-items: baseline;
  vertical-align: middle;

  .MuiChip-root {
    margin-right: .5rem;
  }
}

// notifications
.infoBlock {
  border-radius: 15px;
  background: rgba(0,0,0,0.05);
  padding: 1rem;
  font-size: .9rem;
  display: flex;
  align-items: center;

  &.inline {
    display: inline-block;
  }

  &.minimal {
    padding: .5rem;
  }

  &.alert {
    background: rgba(230, 26, 26, 0.98);
    color: #fff;
  }

  .MuiSvgIcon-root {
    margin-right: 1rem;
    opacity: .5;
  }
}

.ActionButton {
  cursor: pointer;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-size: 0;

  margin-right: 3px;

  &.disabled {
      opacity: .5;
  }

  .MuiSvgIcon-root {
      padding: .5rem;

      background: rgba(255, 255, 255, .95);
      border: 1px solid #ccc;
      border-radius: 50%;

      width: 26px;
      height: 26px;
      line-height: 26px;
  }
}
