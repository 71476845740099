.NodeLinesTemplate {
    .ShopingCartButton {
        .MuiButtonBase-root {
            background: #333333c9;
        }

        &.selected .MuiButtonBase-root {
            background: #ffffffc9;
            outline: 1px solid #fff;
        }
    }
}
