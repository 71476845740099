.ShopProductsTable {
    table {
        border-collapse:collapse;
        width: 100%;
        border-radius: 15px;
    }

    table:nth-child(odd) {
        background-color: #f6f6f6;
    }

    td {
        vertical-align: middle;
        padding: .5rem;
    }

    .button {
        width: 64px;
        text-align: right;
    }

    .ShopingCartButton {
        width: 64px;
    }

    .ShopProductPrice {
        padding-top: 0;
        padding-bottom: 0;
    }
}
