.BannerCarousel.MainBannerCarousell .BannerPreloader {
  // margin-left: 1rem;
  // margin-right: 1rem;
  margin: 0 auto;
  width: calc(100% - 1rem);

  @media (min-width: 600px) {
    width: 100%;
  }
}

.BannerCarousel.MainBannerCarousell {
    position: relative;  
    z-index: 2;

    margin-bottom: 2.8rem;
  
    .preloaderWrap {
      color: #fff;
      z-index: 1;
    }

    .Banner {
      @media (min-width: 600px) {
          margin-left: 0;
          margin-right: 0;
      }
    }

    .Banner {
      &.BannerWithButton {
        .BannerImgWrap,
        .BannerImg {
          height: 144px!important;
          box-shadow: none;
        }
      }
    }
  
    .swiper-pagination-bullet {
      background: #ddd;
    }

    .swiper-pagination-bullet-active {
      background: #fff;
    }

    .MuiButtonBase-root {
      color: #fff;
      opacity: 0.2;
      margin: 0 .3rem;
  
      .MuiSvgIcon-root {
        width: 0.8rem;
        height: 0.8rem;
      }
    }

    // &.active0 {
    //     .MuiButtonBase-root:nth-child(1) {
    //         opacity: 1;
    //     }
    // }

    // &.active1 {
    //     .MuiButtonBase-root:nth-child(2) {
    //         opacity: 1;
    //     }
    // }

    // &.active2 {
    //     .MuiButtonBase-root:nth-child(3) {
    //         opacity: 1;
    //     }
    // }

    @media (min-width: 600px) {
      .BannerWithButton {
          .BannerImgWrap {
            // box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.2);

            .BannerImg {
                height: 90%!important;
                width: auto!important;
                // box-shadow: none;
            }
          }
      }
    }
  }