.Carousel {
    .swiper-pagination {
        position: relative;
        margin-top: 1rem;
    }

    .swiper-pagination-bullet {
        background: #777;
    }

    .swiper-pagination-bullet-active {
        background: #333;
    }

    &.hasNavigation {
        .swiper-button-prev,
        .swiper-button-next {
            color: #333;
            background: rgba(255, 255, 255, .9);
            border-radius: 50%;
            width: 60px;
            height: 60px;
            margin-top: -55px;

            &::after {
                font-size: 26px;
            }
        }

        .swiper-button-prev {
            margin-left: 4px;
            box-shadow: 4px 0 20px #444;
        }

        .swiper-button-next {
            margin-right: 4px;
            box-shadow: -4px 0 20px #444;
        }
    }

    // &.hasNavigation {
    //     .swiper-slide-active {
    //         padding-left: 80px;
    //     }
    // }
}

.Carousel {
    .CarouselPhotos {
        .swiper-autoheight .swiper-wrapper {
            align-items: center;
        }

        .swiper-slide {
            box-sizing: border-box;
            padding: 0 .5rem;

            @media (min-width: 600px) {
                padding: 0 1rem;
            }

            .image {
                border-radius: 15px;
            }
        }
    }
}
