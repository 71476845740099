.Content {
    .pageTitle {
        font-size: 1.5rem;
        line-height: 30px;

        margin-top: 0;
        margin-bottom: 0;
        padding-right: .5rem;
        padding-top: 0.2rem;
        font-weight: normal;
        font-weight: 300;
        word-break: break-word;

        @media (min-width: 600px) {
            font-size: 2rem;
            line-height: 1.1;
            padding-top: 0;
        }
    }

    .photoWrap {
        font-size: 0;

        img {
            max-width: 100%;
        }
    }

    figure,
    img {
        max-width: 100%;
        margin: 0;
    }

    .accessError {
        padding: 20px;
    }

    .labelWrap {
        padding-left: 1rem;

        @media (min-width: 600px) {
            padding-left: 1.5rem;
        }
    }
}

.noResults {
    padding: 5rem 2rem;
    opacity: .6;
}

.pathValues {
    margin-bottom: 2rem;
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 1rem;
}

.AdminNodeInfo {
    margin-top: 4rem;
    font-size: 12px;
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 1rem;

    .labelWrap {
        margin: 0;
        padding: 0;
    }
}
