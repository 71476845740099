.Doctor {
    .details {
        .photo {
            width: 160px;
            // margin-right: 1rem;
            float: left;
            border-radius: 15px;
            margin-bottom: 1rem;

            @media (min-width: 600px) {
                margin-bottom: 2rem;
            }
        }

        .content {
            margin-left: 180px;
        }

        // @media (min-width: 600px) {
        //     .content {
        //         margin-left: 180px;
        //     }
        // }
    }

    .region {
        .MuiChip-label,
        .MuiChip-root {
            padding: 0;
            background: none;
            border: 0;
            font-size: 1rem;
        }
    }

    .LocationWidget {
        clear: both;
    }
}
