.MessengerValue {
    white-space: nowrap;

    .Icon {
        margin-right: .2rem;
        vertical-align: middle;
        margin-left:  -.3rem;
    }

    .link {
        vertical-align: middle;
    }
}
