.NodeImage {
    width: 50px;
    float: left;
    margin-right: 1rem;

    .ContentImage {
        width: 100%;
        border-radius: 5px;
    }
}
