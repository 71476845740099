.JSONEditor {
    font-size: 1rem;

    .jv-chevron {
        display: none;
    }

    code {
        padding: 16.5px 14px;
        font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif;
        border: 1px solid rgb(192, 192, 192);
        border-radius: 20px;
    }

    &.error code {
        border-color: rgb(211, 47, 47);
    }

    .json-view :is(.json-view--copy, .json-view--edit) {
        width: 2rem;
        height: 2rem;
        position: relative;
        top: 10px;

        path {
            fill: #999;
        }
    }

    .json-view--pair {
        margin: 0.5em 0;
    }

    .json-view--input,
    .json-view--null,
    .json-view--boolean,
    .json-view--string {
        padding: .3rem .8rem;
    }

    .json-view :is(.json-view--copy, .json-view--edit) {
        display: inline;
    }

    .exampleJSON code {
        background: #eee;
        opacity: .5;
    }

    .additionalFields,
    .instruction {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: .8rem;
        cursor: pointer;
    }

    &.disabled {
        code {
            color: #aaa!important;
            border: 1px solid #eaeaea!important;
            background-color: #f9f9f9!important;
        }
    }
}

