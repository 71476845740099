.sortRangeWrap {
    .sortDirection {
        margin-left: .5rem;
        border-bottom: 1px dashed #888;

        &:hover {
            border-bottom: 1px dashed #fff;
        }

        .MuiButtonBase-root {
            text-decoration: none;
            text-transform: none;
            padding: 0;
            margin: 0;
            border: 0;
            font-weight: 400;

            svg {
                margin-left: .3rem;
            }
        }
    }
}