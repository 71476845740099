.Events {
    .pageTopTitle .filterButtons .FiltersLine .FilterField {
        width: 112px;
    }

    .pastEvents {

    }

    .currentEvents {
        margin-bottom: 3rem;
    }

    .pastEventsTitle {
        color: #333;
        margin-top: 0;
    }

    .Breadcrumbs {
        display: flex;
        align-items: center;

        .currentDate {
            font-size: 1.3rem;
            font-weight: 300;
            color: #777;
        }

        .removeDay {
            margin-left: .5rem;

            svg {
                vertical-align: top;
                color: #999;
                cursor: pointer;
                width: 1.2rem;
                height: 1.2rem;
                top: -2px;
                left: -5px;
                position: relative;
            }
        }
    }

    .noResults {
        text-align: left;
        padding-left: .5rem;
        padding-top: 8.4rem;
    }

    .MuiBreadcrumbs-separator {
        color: #aaa;
    }

    .MuiBreadcrumbs-ol {
        margin-top: 0;
        color: #333;
    }

    .LinksTileItem {
        .title {
            .date {
                font-size: .9rem;
                margin-bottom: .2rem;
                font-weight: 300;
                display: block;
            }

            .sep {
                margin: 0 .5rem;
            }
        }
    }

    .noDataInPeriod {
        padding: 2rem .5rem;
        color: #999;
    }

    .selectedFilters {
        margin-bottom: 1.3rem;

        .selectedFiltersLabel {
            font-size: 14px;
            line-height: 30px;
            height: 32px;
            white-space: nowrap;
        }
    }

    .CalendarWidget {
        .PreloaderCalendar {
            .title {
                margin-left: 2rem;
            }

            .header {
                .MuiSkeleton-root {
                    margin: 2px 5px!important;
                }
            }
        }
    }

    .ContentDesktop {
        .noDataInPeriod {
            padding-top: 2.2rem;
        }

        @media (min-width: 600px) {
            margin-top: .6rem;
        }
    }

    .CalendarWidgetDesktop {
        padding-left: 1rem;
        margin-top: -1.2rem;
        margin-bottom: 2rem;

        @media (min-width: 600px) {
            margin-top: -.6rem;
        }

        .Mui-selected,
        .Mui-selected:hover,
        .Mui-selected:focus {
            background: #fff;
            border: 1px solid #1976d2;
            color: #333;
            text-shadow: none;
        }

        .MuiDateCalendar-root {
            margin: 0;
            margin-left: -1.2rem;
        }
    }
}
