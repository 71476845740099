.Avatar {
    background: #fff;
    border-radius: 50%;
    border: 1px solid #fff;
    overflow: hidden;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;

    &.noPhoto {
        border-color: rgba(255, 255, 255, 0.5);
    }
}

.AvatarWrap {
    font-size: 0;
    display: inline-block;
    position: relative;

    .roles {
        position: absolute;
        bottom: 2px;
        left: 50%;
        transform: translateX(-50%);

        width: 100%;
        box-sizing: border-box;

        color: #fff;
        background: rgba(0, 0, 0, 0.8);
        padding: 4px;
        border-radius: 5px;

        text-align: center;
        font-size: 12px;
        line-height: 12px;

        // white-space: nowrap;
    }

    &.AvatarMinimal {
        .Avatar {
            width: 32px;
            height: 32px;
            min-width: 32px;
            min-height: 32px;
        }
        
        .roles {
            font-size: 8px;
            line-height: 8px;
            padding: 2px 1px;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}
