.PartnershipAdminPage {
    .MuiAccordionSummary-content {
        font-weight: 500;
    }

    .PartnerLinksSection {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        font-weight: 300;
    }

    .PartnerLinksItem {
        margin-bottom: 1rem;
        padding-left: 1rem;

        &:last-child {
            margin-bottom: 0;
        }

        .MuiChip-root {
            margin-left: 1rem;
        }
    }

    .Mui-expanded .MuiBadge-badge {
        display: none;
    }
}
