.Notify {
    color: #fff;

    // max-height: 0px;
    overflow: hidden;

    transition:
        // max-height .1s ease-out,
        bottom .1s ease-out,
        background .3s ease-out;

    border-radius: 15px;

    position: fixed;

    bottom: -200px;
    transform: translateX(-50%);
    left: 50%;
    width: calc(100% - 1rem);

    @media (min-width: 600px) {
        width: calc(560px);
        transform: translateX(-50%);
        left: 50%;
        // bottom: auto;
        // top: .5rem;
    }

    &.NotifyWrapper {
        position: relative;
        display: none;
    }

    &.open {
        // max-height: 300px;
        bottom: 10px;
        box-shadow: 0 1px 10px rgba(0,0,0,.4);
        background: rgba(255, 255, 255, 0.5);

        transition:
            // max-height .1s ease-in,
            bottom .1s ease-in,
            background .3s ease-in;

        &.success {
            background: rgba(3, 162, 3, 0.98);
        }
    
        &.error {
            background: rgba(230, 26, 26, 0.98);
        }

        @media (min-width: 600px) {
            bottom: auto;
            top: 25%;
            // bottom: 10%;
            // bottom: 100px
        }
    }

    .wrapper {
        max-width: 599px;
        margin: 0 1rem 0 1rem;

        padding-top: .7rem;
        padding-bottom: .7rem;

        border-radius: 15px;

        font-size: 0.9rem;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    a {
        color: #fff;
    }

    .message {
        line-height: 1.4;
        max-height: 300px;
        overflow: auto;
        padding: 1rem 0;

        p:first-child {
            margin-top: 0;
        }
        p:last-child {
            margin-bottom: 0;
        }
    }

    .actions {
        width: 120px;
        min-width: 120px;
        text-align: right;
    }

    .action {
        cursor: pointer;
        display: inline-flex;
        margin-left: 1rem;
        justify-content: end;
        align-items: center;
        border: 1px solid rgba(255, 255, 255, .3);
        border-radius: 50%;
        padding: .5rem;

        transition: 
            color .5s ease-out,
            background .3s ease-out;
        &:hover {
            background: rgba(255, 255, 255, .2);
        }
    }
}
