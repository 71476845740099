.Content {
    .SaleTemplate {
        .SalePrice {
            font-size: 2rem;
            margin: 1rem 0;
            padding-left: 1rem;

            @media (min-width: 600px) {
                padding-left: 1.5rem;
            }
        }

        .SaleActions {
            margin-top: 2rem;

            .SaleActionsLabel {
                font-style: italic;
                padding-left: 1rem;
                color: #555;

                @media (min-width: 600px) {
                    padding-left: 1.5rem;
                }
            }
        }

        .CarouselPhotos {
            margin-bottom: 1rem;
        }
    }
}
