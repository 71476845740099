.LinksTileWithCategories {
    
    .CategoryWrap {
        margin-bottom: 1rem;
    }

    a {
        font-size: inherit;
        // text-decoration: none;
    }

    // .header {
    //     text-align: left;
    //     font-size: 1.5rem;
    //     font-weight: bold;
    // }
    
    // .title {
    //     padding: 0 .5rem;
    //     text-align: left;
    //     font-weight: bold;
    //     line-height: 1.3;
    //     margin-bottom: .5rem;
    // }

    // .filtersWrap {
    //     padding-left: 0;
    //     margin-top: 0rem;
    //     margin-bottom: 1rem;
    // }

    // @media (min-width: 600px) {
    //     .filtersWrap {
    //         margin: 0;

    //         .FiltersLine {
    //             top: -72px;
    //         }
    //     }
    // }

    .title {
        .sectioCategory {
            display: flex;
            position: relative;

            @media (min-width: 600px) {
                &:hover .sectionAnchor {
                    display: block;
                    opacity: .4;
                    left: -1rem;
                    padding-right: .5rem;
                }
            }
        }

        .sectionAnchor {
            position: absolute;
            display: none;
            transition: opacity .5s ease-in-out;
        }

        .MuiChip-label,
        .MuiChip-root {
            display: inline-block;
            background: none;
            border: 0;
            padding: 0;

            font-size: 1.2rem;
            font-weight: 300;
        }
    }

    .subtitle {
        position: relative;
        text-align: left;
        padding-left: .5rem;
        word-break: break-word;
        display: flex;
        align-items: center;
        line-height: 1.3rem;

        min-height: 42px;
        overflow: hidden;

        .arrow {
            width: 3rem;
            height: 3rem;
            fill: #000;
            opacity: 0.2;

            position: absolute;
            right: -.5rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    a {
        .hasDescription {
            .subtitle {
                padding-right: 2.4rem;
            }
        }
    }

    .short {
        padding: 0 .5rem;
        // padding: 0 .5rem;
        text-align: left;
        font-size: 1rem;
        line-height: 1.3;
        color: #777;
    }

    .coverWrap {
        position: relative;

        margin-bottom: 1rem;
        
        &.noLink {
            .subtitle, .photoWrap {
                opacity: .5;
            }
        }

        .photoWrap {
            font-size: 0;
            margin-bottom: 1rem;
        }
        
        .thumbnail,
        .poster,
        .baner {
            width: 100%;
            float: none;
        }
    }
}
