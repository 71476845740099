.Chat {
    overflow: hidden;

    .ChatLogo {
        border-radius: 15px;

        @media (min-width: 600px) {
            width: 160px;
            float: left;
            margin-right: 2rem;
        }
    }
}
