.HistoryShopAdminPage {
    .TableWrap {
        margin-bottom: 2rem;

        .labelWrap.data_text {
            display: block;
        }
    }

//     .FiltersLine {
//         margin-bottom: 1rem;
//     }

//     .labelWrap.node {
//         align-items: start;

//         .labelValue {
//             padding-top: .2rem;
//         }
//     }

//     .RestoreAction {
//         margin-bottom: 2rem;
//     }

//     .labelWrap.patch {
//         align-items: start;
//         .label {
//         }
//         .labelValue {
//             padding-top: .2rem;
//         }
//     }
}

.HistoryShopAdminPageDetails {
    padding: 1rem;
}
