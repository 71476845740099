.notPublished {
    font-size: 20px;
    line-height: 20px;
    margin-right: .5rem;
}

.TableWrap {
    .notPublished {
        padding: 0;
        font-size: 20px;
        line-height: 20px;
    }   
}

.LibraryTemplate {
    .subtitle {
        .notPublished {
            padding-left: 0;
            padding-top: 0;
            margin-top: -.3rem;
        }
    }
}

.notPublishedWrap {
    display: inline-flex;
    align-items: center;
}
