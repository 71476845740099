.Footer {
    font-size: 12px;
    margin: 1rem 0;
    z-index: 99;
    
    position: relative;
    align-items: center;
    justify-content: start;
  
    span,
    a {
      margin-left: .5rem;
      display: inline-flex;
    }
  
    a, .pseudoLink {
      opacity: .75;
    }

    a:hover {
      opacity: 1;
      transition: opacity ease-in-out .2s;
    }
  
    .sep {
      margin-left: .5rem;
      opacity: .6;
    }


    .madeInUkraine {
      display: flex;
      text-decoration: none;
      margin-bottom: 1rem;
      margin-top: 1rem;

      .logo {
        width: 42px;
        margin-left: .5rem;
      }
    }
  
    @media (min-width: 600px) {  
      display: flex;

      width: calc(900px + 2rem);
  
      padding-left: 1rem;
      padding-right: 1rem;

      color: #fff;
      margin-left: auto;
      margin-right: auto;
  
      color: #fff;
  
      .madeInUkraine {
        position: absolute;
        right: 1rem;
        text-decoration: none;
        margin: 0;
      }

      a {
        color: #fff;
      }
    }
}


.SwipeableDrawer {
  .Footer {
    padding-top: 0;
    margin-top: 0;
  }
}
