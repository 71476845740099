.AdminLibraryPage {
    .td_title {
        .MuiChip-root {
            height: auto;
            padding-top: 4px;
            padding-bottom: 4px;
            text-align: center;
        }

        .title + .status {
            margin-top: .5rem;
        }

        .MuiChip-label {
            white-space: break-spaces;
        }
    }

    .Details {
        .labelWrap.notes,
        .labelWrap._reading_by,
        .labelWrap._created_by {
            display: block;
        }

        .labelWrap.notes {
            color: #C00;
        }

        ._reading_by {
            .labelValue {
                display: block;
            }
            .actions {
                display: flex;
                flex-direction: row;
                padding-top: 1rem;
    
                @media (max-width: 599px) {
                    padding-top: 0;
                    flex-direction: column;                
                }
    
                .MuiButton-root {
                    margin-right: 1rem;
    
                    &:last-child {
                        margin-right: 0;
                    }
    
                    @media (max-width: 599px) {
                        margin: 0;
                        margin-top: 1rem;
                    }
                }
            }
    
            .deposit {
                margin-top: 1rem;
            }
        }
    }
}
