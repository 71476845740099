.NodeActions {
    // white-space: nowrap;

    position: absolute;
    right: 0;
    bottom: 0;

    // width: 56px;

    .MuiButtonBase-root {
        display: flex;
        margin: 0;
        padding: 0;
        margin-bottom: .5rem;
        border-radius: 50%;
        box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.1);
    }
}

// .NodeManipulationsButton {
//     .transparentBack {
//         position: fixed;
//         left: 0;
//         top: 0;
//         width: 100%;
//         height: 100%;
//         cursor: pointer;
//     }
// }
