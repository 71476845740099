.TourComplexity {
    display: flex;
    // align-items: center;

    .TourComplexityLabel {
        font-style: italic;
        color: #777;
        padding-right: .5rem;
    }

    .information {
        margin-left: .5rem;
        cursor: pointer;
    }
}
