.actionsList {
    position: relative;
    height: 4rem;

    .actionsListButtons {
        position: fixed;
        z-index: 100;
        bottom: 72px;
        height: 0;

        width: calc(100% - 2rem);

        text-align: right;

        display: flex;
        justify-content: right;
    }

    @media (min-width: 600px) {
        height: 0;

        .actionsListButtons {
            width: calc(900px + 2rem);
        }
    }

    button {
        margin-left: .5rem;
        border-radius: 50%;
    }

    .NodeActionsButton {
        min-width: 56px;

        &.Close {
            background: #eee;
            color: #333;
        }
    }

    .MuiFab-contrast,
    .MuiFab-contrast:hover,
    .MuiFab-contrast:focus {
        background: #333;
        color: #fff;
        box-shadow: none;
    }

    .MuiFab-neutral,
    .MuiFab-neutral:hover,
    .MuiFab-neutral:focus {
        background: #fff;
        color: #333;
        border: 1px solid #ccc;
        box-shadow: none;
    }
    
    .validateContentIcon,
    validateContentIcon:hover{
        color: #33a448;

        &.Unpublish {
            color: #df4242;
        }
    }

    .removeContentIcon,
    .removeContentIcon:hover {
        color: #df4242;
    }
}


.AddNodeMenu {
    .MuiPopover-paper {
        margin-top: -74px;
    }
}

.addContentIconMenu {
    // padding: 0 .5rem;
    overflow: hidden;
    padding: 0;
    margin: 0;
    margin-top: -1rem;
    min-width: 280px;

    @media (min-width: 600px) {
        margin-top: 0;
    }

    .node {
        display: flex;
        align-items: center;
        width: 90%;
        overflow: hidden;

        svg {
            margin-right: .4rem;
        }

        @media (min-width: 600px) {
            padding: 1rem;
        }
    }

    .menuItem {
        margin-top: .5rem;

        &.fullWidth {
            width: 100%!important;
        }

        &.first {
            clear: both;
            position: relative;

            &:before {
                content: '';

                overflow: hidden;
                height: 1px;
                width: 200%;

                font-size: 0;


                background: #eee;
                position: absolute;
                left: 0;
                top: -0.5rem;
            }

            &:first-child {
                &:before {
                    display: none;
                }
            }
        }

        &.first,
        &.second {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }

        &.last {
            margin-bottom: 0;
            // padding-bottom: 2rem;
        }
    }

    .MuiPaper-root {
        margin-top: -76px;
        padding: .5rem;
    }

    .MuiDivider-root:last-child {
        display: none;
    }

    .MuiList-root {
        width: 160px;
    }

    .MuiMenuItem-root {
        width: 100%;
        border-radius: 10px;
        padding: 0;
    }

    &.columns {
        .MuiList-root {
            width: 320px;
        }

        .MuiMenuItem-root {
            width: 50%;
            // padding-left: 1rem;
            float: left;
        }
    }
}

.DialogContentWrapper {
    .addContentIconMenu .menuItem.first,
    .addContentIconMenu .menuItem.second {
        padding-top: 0;
        padding-bottom: 0;
    }

    .addContentIconMenu .menuItem.last {
        margin-bottom: .5rem;
    }
}