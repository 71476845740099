.UserPic {
    .loginLink {
        svg {
            color: #fff;
        }
    }

    .MuiBadge-badge {
        margin-right: 6px;
        margin-top: 6px;
    }

    .menu {
        color: #fff;
        display: none;
    }
}

body.profile .UserPic,
body.error_page .UserPic {
    display: none;
}

body.mobile .pageTop {
    // !.preloader in user picture make scroll on bottom
    overflow: hidden;
}

.userArea {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    cursor: pointer;

    .MuiSnackbar-root {
        margin-left: 2rem;
        margin-right: 2rem;
        top: 9px;

        .MuiSnackbarContent-root {
            background-color: rgba(5,5,5,.8);
            min-width: 240px;
            justify-content: space-between;
            flex-wrap: nowrap;

            .NotifyMessage {
                .icon {
                    margin-right: .5rem;
                }
            }
        }

        .MuiSnackbarContent-action {
            padding: 0;
            margin: 0;
            margin-left: .5rem;
        }

        .MuiSvgIcon-root {
            width: 18px;
            height: 18px;
        }

        @media (min-width: 600px) {
            margin: 0;

            .MuiSnackbarContent-root {
                background-color: rgba(5,5,5,.5);
            }
        }    
    }

    .preloader {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        height: 100%;
        z-index: 1;

        .MuiCircularProgress-root {
            color: #fff;
            width: 40px!important;
            height: 40px!important;
            opacity: .8;
        }
    }

    .loginIcon,
    .location {
        cursor: pointer;
        color: #fff;
        border-radius: 8px;
        padding: .2rem .5rem;
        margin-right: .5rem;
    }

    .loginIcon {
        cursor: pointer;
        margin: 0;
        padding: 3px;
        font-size: 0;
        display: inline-block;
        border-radius: 50%;
    }
}

.SwipeableDrawerContentWrapper {
    .UserCard {
        margin-top: -.3rem;
        margin-bottom: 1rem;

        @media (max-width: 599px) {
            .labelWrap {
                margin-top: .4rem;
                margin-bottom: -.5rem;
            }
        }
    }
}

.SwipeableDrawerContentWrapper {
    .UserLinksAdaptiveMenuWrapper {
        margin: -.5rem;
        margin-bottom: 1rem;

        padding-top: 0;
        padding-bottom: 0;
    }
}

.UserPicLinks .MuiPaper-root.MuiPaper-rounded {
    width: 360px;
}

.UserPicLinks {
    .UserLinksAdaptiveMenuWrapper {
        display: flex;
        flex-wrap: wrap;

        .block {
            position: relative;
            padding: 1rem;
            border: 1px solid #e1e1e1;
            border-radius: 15px;
            margin: .2rem .5rem;

            box-sizing: border-box;
            width: 100%;

            cursor: pointer;

            .links {
                margin: 0 -.5rem;
                margin-bottom: -.5rem;
                padding-top: .5rem;
            }

            .link {
                width: 50%;
                box-sizing: border-box;
                display: inline-flex;
                justify-content: stretch;
            }

            .linkBlock {
                width: 100%;
            }

            .section {
                position: relative;
                display: flex;
                align-items: center;

                .icon {
                    margin-right: .5rem;
                }

                .flag {
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                }

                .up, .down {
                    position: absolute;
                    right: 0;
                    top: 0;
                    opacity: .5;
                }

                .up {
                    display: none;
                }
            }

            &.open {
                .section {
                    color: #777;

                    .up {
                        display: block;
                    }

                    .down {
                        display: none;
                    }
                }
            }
        }

        .link {
            cursor: pointer;

            font-size: 0.9rem;

            box-sizing: border-box;
            display: inline-block;
            text-align: center;

            &:hover {
                background: none;
            }

            .MuiSvgIcon-root {
                width: 20px;
                height: 20px;
                color: #333;
            }
        }

        .linkWrap {
            position: relative;
            border: 1px solid #e1e1e1;
            box-sizing: border-box;
            border-radius: 15px;
            padding: 1rem;
            margin: .5rem;
            width: 100%;
        }

        .MuiSwitch-root {
            margin: -12px 0;
        }

        .switchPreloader {
            display: inline-block;
            position: relative;

            .preloaderWrap {
                margin-top: 2px;
                color: #1876d2;
            }

            .MuiCircularProgress-root {
                width: 30px;
                height: 30px;
            }
        }

        .linkWrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            white-space: nowrap;

            .MuiListItemIcon-root {
                margin-right: 5px;
                min-width: auto;
            }

            .MuiListItemText-root {
                flex: 0;

                .MuiTypography-root {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

        .ExitBtn,
        .ExitBtn .MuiSvgIcon-root {
            color: rgb(148, 0, 0);
        }

        .ExitBtn {
            width: 100%;

            .linkWrap {
                width: auto;
            }
        }
    }
}

.UserCard {
    overflow: hidden;
    position: relative;

    padding-left: 1rem;
    padding-right: 1rem;
    margin: .5rem;

    background: #f1f1f1;

    padding: 1rem;
    border-radius: 15px;

    word-break: break-all;

    .CardTitle {
        margin: 0;
        padding: 0;
        font-size: .9rem;
        
        margin-top: -.1rem!important;
        margin-bottom: -.2rem;

        white-space: nowrap;
        overflow: hidden;
    }

    .CardEmail {
        margin: 0;
        color: #777;
        white-space: nowrap;
        overflow: hidden;
        font-size: .8rem;
    }

    .userActionsList {
        clear: both;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-left: 1rem;
    }

    .ProfileLink {
        width: 50%;
        font-size: 12px;

        display: flex;
        align-items: center;

        margin-top: .5rem;

        .MuiSvgIcon-root {
            margin-right: .5rem;
        }
    }
    
    .UserLogo {
        position: relative;
        margin-right: 0.8rem;

        float: left;
        font-size: 0;
    }

    .labelWrap.absent {
        color: rgb(211, 47, 47);
    }

    p:first-of-type {
        margin-top: 0;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    .income,
    .editProfile,
    .exitBtn {
        cursor: pointer;
        position: absolute;
        top: 14px;
        right: 14px;

        background: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        font-size: 0;
        padding: .3rem;
    }

    .editProfile,
    .exitBtn .MuiSvgIcon-root {
        font-size: 1.5rem;
    }

    .editProfile {
        font-size: 16px;
        top: 11px;

        color: #555;     
    }

    &.WithActions {
        .CardTitle,
        .CardEmail {
            margin-right: 56px;
        }
    }

    .informer {
        border-radius: 15px;
        background: rgba(230, 26, 26, 0.98);
        color: #fff;
        padding: .7rem .5rem;
        margin-top: .5rem;
        font-size: 12px;
        line-height: 14px;
        clear: both;
        display: flex;
        align-items: center;
        word-break: break-word;

        .MuiSvgIcon-root {
            margin-right: .5rem;
        }        
    }
}

body.mobile {
    .UserCard {
        margin: 0;
        margin-bottom: 1rem;

        .CardTitle {
            padding-top: .4rem;
            font-size: 1.2rem;
            margin: 0;
            overflow: hidden;
            margin-right: 52px;
            text-overflow: ellipsis;
        }

        .ProfileLink {
            font-size: .9rem;
            margin-top: 1rem;
        }
        
        .UserLogo {
            margin-right: 1rem;
        }

        .income,
        .editProfile,
        .exitBtn {
            right: 1rem;
            top: 28px;

            padding: .5rem;
        }

        .MuiList-root {
            padding-top: 0;
        }

        .informer {
            margin-top: 1.5rem;
        }
    }

}