.Proposal {
  position: relative;

  padding: 1rem;
  padding-top: .5rem;

  padding-bottom: 0;
  margin-bottom: 0;

  perspective: 1000px;

  .ProposalWrapFront,
  .ProposalWrapBack {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    border-radius: 20px;
    padding: 1rem;

    box-sizing: border-box;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    box-shadow: 0 0 10px rgba(0,0,0,.3);
  }

  .ProposalWrapFront {
    background: linear-gradient(38deg, rgb(255 217 0) 0%, rgb(255 65 65) 50%, rgb(255 225 182) 100%);
  }

  .ProposalWrapBack {
    transform: rotateY(180deg);
    background: #333;
    color: #fff;

    .CloseIcon {
      position: absolute;
      right: 1rem;
      top: 1rem;

      cursor: pointer;

      color: #fff;
    }
  }

  .ProposalWrapBackDetails {
    height: 100%;
    margin-right: -.5rem;
    padding-top: .2rem;

    .detailsWrap {
      overflow: auto;
      height: calc(100% - 40px);
    }

    .details {
      display: flex;

      .MuiSvgIcon-root {
        margin-right: .5rem;
        // margin-left: -2px;
      }
    }

    .companyName {
      text-transform: uppercase;
      padding-bottom: .2rem;
      margin-bottom: .4rem;
    }
  }

  .ProposalWrap {
    position: relative;

    transition: background .5s ease-in-out;
    height: 210px;
    width: 100%;
    // max-width: 380px;
    margin: 0 auto;

    transition: transform 0.5s;
    transform-style: preserve-3d;
  }

  &.flipped .ProposalWrap {
    position: relative;
    transform: rotateY(180deg);
  }

  &.active {
    .ProposalWrapFront,
    .ProposalWrapBack {
      .select {
        background-color: rgba(255,255,255,.2);
        outline: 1px solid #fff;
        color: #fff;
        box-shadow: none;
      }
    }
  }

  .title {
    text-transform: uppercase;
    font-size: 24px;
  }

  .title,
  .price,
  .package {
    display: block;
    font-weight: normal;
    color: #fff;
    text-shadow: 1px 1px 0 #111;
  }

  .package {
    display: inline-block;
    color: #fff;
    background: rgba(0,0,0,.1);
    padding: 0 .5rem;
    border-radius: 5px;
    margin-left: -5px;
  }

  .cover {
    // display: flex;
    // align-items: center;
    
    color: #333;
    font-size: .8rem;
    line-height: 1.2rem;

    position: absolute;
    right: 1.5rem;
    top: 82px;

    text-align: right;

    .covarage {
      display: block;
      font-size: 1.4rem;
      text-shadow: 1px 1px 0 rgba(255,255,255,.3);
    }
    // top: 50%;
    // transform: translateY(-50%);
  }

  .proposalDetails {
    position: absolute;
    right: .5rem;
    top: .7rem;
    display: flex;

    svg {
      margin-left: .5rem;
    }
  }

  .priceBlock {
    .price {
      font-size: 42px;
      line-height: 42px;
      padding-bottom: 15px;
      padding-top: 10px;
    }
  
    .discount {
      text-decoration: line-through;
      color: #800;
      font-size: 20px;
    }
  
    .details {
      font-size: 10px;
      padding-right: 150px;
    }
  }  

  .select {
    position: absolute;
    right: .7rem;
    bottom: .7rem;

    min-width: 132px;
  }
}

.ProposalsCarousel {
  position: relative;
  margin-left: -1rem;
  margin-right: -1rem;
  height: 280px;

  > div:first-child > div {
    height: 210px!important;
  }

  .Proposal {
    margin-left: 1px;
    margin-right: 1px;
  }

  .MuiButtonBase-root {
    margin-left: .1rem;
    margin-right: .1rem;
  }

  .preloaderWrap {
    margin-top: -2rem;
  }
}

.ProposalsDesktopTile {
  display: flex;
  flex-wrap: wrap;

  .Proposal {
    flex: 1 0 100%;

    @media (min-width: 600px) {
      flex: 1 0 40%;
    }

    @media (min-width: 600px) {
      flex: 1 0 46%;
    }
  }
}
